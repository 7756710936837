import Vue, {VueConstructor} from "vue"
import Session from "lib/security/Session"
import {checkMaintenance} from "store/maintenance"

export default (session: Session<any>, roles?: ReadonlyArray<string>) => <V extends VueConstructor<Vue>, W extends V>(component: V): W => {
	return Vue.extend({
		extends: component,
		mixins: [
			{
				async created() {
					const maintenance = await checkMaintenance()
					if (maintenance && maintenance.active) {
						await session.logout()
						setTimeout(() => {
							location.href = "maintenance.html"
						}, 1000)
					} else {
						await session.verify(roles)
					}
				}
			}
		]
	}) as unknown as W
}
