<template>
	<div class="documents-table">
		<information-modal />
		<div class="documents-table__content">
			<div v-if="documentsToUpload.length">
				<h3 class="content__heading" :class="{'heading--single': !documentsUploaded.length}">Deze documenten hebben wij nog van u nodig</h3>
				<div class="documents-table__row documents-table__row--heading row">
					<div class="row__item col-lg-7 col-md-7 col-xs-6">Document</div>
					<div class="row__item col-lg-5 col-md-5 col-xs-6">Status</div>
				</div>
				<div v-for="(document, index) in documentsToUpload" :key="index">
					<div class="row documents-table__row">
						<div class="row__item col-lg-7 col-md-7 col-xs-6">
							<p class="item__value">
								<information-link 
									v-if="helpText(document.typeExternalIdentifier)"
									:id="document.identifier"
									:label="document.name" 
									:text="helpText(document.typeExternalIdentifier).text" 
									:infoComponent="helpText(document.typeExternalIdentifier).infoComponent" 
									@click="tooltipClicked()"
								/>
								<span v-else>{{ document.name }}</span>
							</p>
						</div>
						<div class="row__item row__item--status col-lg-5 col-md-5 col-xs-6">
							<p class="item__value item--status">{{ document.statusName }}</p>
							<template v-if="document.typeExternalIdentifier !== 'akkoordGeldverstrekker'">
								<template v-if="inPage">
									<link-button class="button--secondary" :href="`upload.html?docId=${document.identifier}`" @click="uploadClicked($event)">Upload</link-button>
								</template>
								<template v-else>
									<action-button class="button--secondary" @click.prevent="uploadSingleFile(document.identifier, $event)">Upload</action-button>
								</template>
							</template>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<p class="content__heading heading--single">Wij hebben alle benodigde documenten ontvangen en zijn voor u aan de slag!</p>
			</div>

			<div v-if="documentsUploaded.length">
				<h3 class="content__heading" :class="{'heading--single': !documentsUploaded.length}">Ontvangen documenten</h3>
				<div class="documents-table__row documents-table__row--heading row">
					<div class="row__item col-lg-7 col-md-7 col-xs-6">Document</div>
					<div class="row__item col-lg-5 col-md-5 col-xs-6">Status</div>
				</div>
				<div v-for="(document, index) in documentsUploaded" :key="index">
					<div class="documents-table__row row">
						<div class="row__item col-lg-7 col-md-7 col-xs-6">
							<p class="item__value">{{ document.name }}</p>
						</div>
						<div class="row__item row__item--status col-lg-5 col-md-5 col-xs-6">
							<p class="item__value item--status">{{ document.statusName }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import { ClaimDocument } from "store/policies/types"

	import ActionButton from "components/button/ActionButton.vue"
	import LinkButton from "components/button/LinkButton.vue"
	import helpText from "leven-shared-components/data/documenthelptexts"
	import InformationModal from "leven-shared-components/InformationModal.vue"
	import InformationLink from "custom/InformationLink.vue"

	@Component({
		components: {
			ActionButton,
			LinkButton,
			InformationModal,
			InformationLink
		}
	})

	export default class DocumentsTable extends Vue {
		@Prop({type: Array, required: true }) data!: Array<ClaimDocument>
		@Prop({type: Boolean, default: false}) inPage!: boolean

		helpText = helpText

		get documentsToUpload(): Array<ClaimDocument> {
			return this.data.filter(document => document.statusId === "toBeReceived")
		}
		get documentsUploaded(): Array<ClaimDocument> {
			return this.data.filter(document => (document.statusId === "awaitingApproval" || document.statusId === "accepted") && document.name)
		}

		uploadSingleFile(documentId: string, event: MouseEvent) {
			this.$emit("uploadSingleFile", documentId)
			this.uploadClicked(event)
		}

		uploadClicked(event: MouseEvent) {
			this.$emit("uploadClicked", { event })
		}

		tooltipClicked() {
			this.$emit("tooltipClicked")
		}
	}
</script>
<style>
	@import "custom/documents-table.css";
</style>
