<template>
    <div class="tabs-wrapper">
        <div class="tabs-list" :class="{ vertical: isVertical }">
            <slot />
        </div>
        <div v-if="$slots.content" class="tabs-content">
            <slot name="content" />
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import { Prop } from 'vue-property-decorator'
    
    @Component
    export default class TabList extends Vue {
        @Prop({ type: Boolean, required: false }) isVertical?: boolean
    }
</script>

<style scoped>
    .tabs-list {
        display: flex;
        margin: 16px 0;
    }

    .tabs-list:not(.vertical) {
        gap: 3rem;
    }

    .tabs-list.vertical {
        flex-direction: column;
    }
</style>
