<template>
	<button type="button" class="button" :disabled="disabled" @click="clicked"><slot></slot></button>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"

	import eventBus from "lib/vue/eventBus"

	@Component
	export default class ActionButton extends Vue {
		@Prop({default: false, type: Boolean}) disabled!: boolean
		@Prop({required: false, type: String}) event!: string

		clicked(e: MouseEvent) {
			this.$emit("click", e)
			if (this.event) {
				eventBus.emit(this.event)
			}
		}
	}
</script>

<style>
	@import "button/action-button.css";
</style>