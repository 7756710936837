<template>
	<span>
		<span v-if="protect">
			<a v-if="$slots.button && $slots.button[0].tag === 'a'" :id="$attrs.id" :class="childClass" href="#" @click.prevent.stop="showAndClearProtectedText">{{ title }}</a>
			<button v-else :id="$attrs.id" type="button" class="button button--secondary no-margin" @click="protectedText = true">{{ title }}</button>
		</span>
		<slot v-else name="button"></slot>
		<span role="alert">
			<div v-if="protectedText" class="protected-text">U kunt deze wijziging of actie niet doorvoeren.<br>Dit kan alleen door de klant gedaan worden.</div>
		</span>
	</span>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop, Watch} from "vue-property-decorator"

	@Component
	export default class ProtectedButton extends Vue {
		@Prop({type: Boolean, required: true}) protect!: boolean
		@Prop({type: String, required: true}) title!: string
		@Prop({type: Boolean, required: false, default: false}) showProtectedText!: boolean
		@Prop({type: String, required: false}) childClass?: string

		protectedText = false

		@Watch("showProtectedText")
		setProtectedText() {
			this.protectedText = this.showProtectedText
		}

		showAndClearProtectedText() {
			this.protectedText = true
			setTimeout(() => this.protectedText = false, 3000)
		}
	}
</script>

<style>
	.protected-text {
		color: red;
	}
</style>
