<template>
	<div class="widget-form">
		<nav class="progress-bar" v-if="pageIndex > 0">
			<ul>
				<li :class="{selected: pageIndex === 1, completed: !inPage && pageIndex > 1}">{{ inPage ? 1 : "" }}</li>
				<li :class="{selected: pageIndex === 2, completed: !inPage && pageIndex > 2}">{{ inPage ? 2 : "" }}</li>
				<li :class="{selected: pageIndex === 3, completed: !inPage && pageIndex > 3}">{{ inPage ? 3 : "" }}</li>
				<li :class="{selected: pageIndex === 4}" v-if="!initiatedByAdvisor">{{ inPage ? 4 : "" }}</li>
			</ul>
		</nav>
		<div ref="widget"></div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { namespace, State } from "vuex-class"
	import { Prop } from "vue-property-decorator"

	import { CustomerState } from "store/customer/types"
	import { Policy } from "store/policies/types"

	import { fetchSsoCredentials } from "api/request"
	import { clientScript } from "api/settings"

	import { StoreAction } from "lib/types/vuex"
	import eventBus from "lib/vue/eventBus"

	import { AnnuityExpirationDestination } from "store/routing/types"

	declare const commandclient: any
	
	const routing = namespace("routing")
	const policies = namespace("policies")

	@Component
	export default class WidgetForm extends Vue {
		@Prop({type: Boolean, default: false}) inPage!: boolean
		@Prop({type: String, required: true}) routingUrl!: string
		@Prop({type: Boolean, required: true}) initiatedByAdvisor!: boolean

		@State customer!: CustomerState
		@State axonImpersonation!: boolean

		@routing.State selectedWidget?: "addAfkoop" | "addExpiratie" | "complete"
		@routing.State annuityExpirationDestination!: AnnuityExpirationDestination
		@routing.Getter selectedPolicy?: Policy
		@policies.Action getClaimDocuments!: StoreAction<Policy>

		widgetPages: Array<string> = []
		pageIndex = 0

		expectedScreen: string | null = "screen1"

		watchWidgetEvents() {
			if (this.$refs.widget) {
				const informationLinks = (this.$refs.widget as HTMLElement).querySelectorAll("a.informationPopupToggle:not(.nonApplicable)")

				informationLinks.forEach(infoLink => {
				 	infoLink.addEventListener("click", this.showInfolinkText)
				})

				const buttonWaardeopgave = (this.$refs.widget as HTMLElement).querySelectorAll("div.button > button.ng-scope:not(.close):not(.next)")

				buttonWaardeopgave.forEach(infoLink => {
					infoLink.addEventListener("click", this.showWaardeopgaveText)
				})

				const screen1 = (this.$refs.widget as HTMLElement).querySelector("#S1") as HTMLElement

				if (screen1) {
					const screen1Active = screen1.classList.value.includes("active")

					if (screen1Active && this.expectedScreen === "screen1") {
						this.expectedScreen = null

						const screen1NextButton = screen1.querySelector("#_Form_next")
						if (screen1NextButton) {
							screen1NextButton.addEventListener("click", () => {
								this.scrollToTop()
								this.expectedScreen = "screen2"
							})
						}
					}
				}

				const screen2 = (this.$refs.widget as HTMLElement).querySelector("#S2") as HTMLElement

				if (screen2) {
					const screen2Active = screen2.classList.value.includes("active")

					if (screen2Active && this.expectedScreen === "screen2") {
						this.expectedScreen = null

						const screen2PrevButton = screen2.querySelector("#_Form_previous")

						if (screen2PrevButton) {
						 	screen2PrevButton.addEventListener("click", () => {
								this.scrollToTop()
								this.expectedScreen = "screen1"
							})
						}

						const screen2NextButton = screen2.querySelector("#_Form_next")
						if (screen2NextButton) {
							screen2NextButton.addEventListener("click", () => {
								this.scrollToTop()
								this.expectedScreen = "screen3"
							})
						}
					}
				}

				const screen3 = (this.$refs.widget as HTMLElement).querySelector("#S3") as HTMLElement

				if (screen3) {
					const screen3Active = screen3.classList.value.includes("active")

					if (screen3Active && this.expectedScreen === "screen3") {
						this.expectedScreen = null

						const screen3PrevButton = screen3.querySelector("#_Form_previous")

						if (screen3PrevButton) {
							screen3PrevButton.addEventListener("click", () => {
								this.scrollToTop()
								this.expectedScreen = "screen2"
							})
						}

						const screen3NextButton = screen3.querySelector("#_Form_finishSave")

						if (screen3NextButton) {
							screen3NextButton.addEventListener("click", () => {
								this.scrollToTop()
								this.expectedScreen = "screen4"
							})
						}
					}
				}

				const screen4PrevButton = (this.$refs.widget as HTMLElement).querySelector("#_AddClaimStepConfirmation_Previous") as HTMLElement

				if (screen4PrevButton && this.expectedScreen === "screen4") {
					this.expectedScreen = null

					screen4PrevButton.addEventListener("click", () => {
						this.scrollToTop()
						this.expectedScreen = "screen3"
					})
				}

				const screen4NextButton = (this.$refs.widget as HTMLElement).querySelector("#_AddClaimStepConfirmation_Save") as HTMLElement
				if (screen4NextButton) {
					if (this.axonImpersonation) {
						screen4NextButton.style.display = "none"
					} else {
						screen4NextButton.addEventListener("click", () => {
							this.scrollToTop()
						})
					}
				}

			}
		}

		showInfolinkText(event: Event) {
			 if (event.target instanceof Element) {
				const infoKey = "information"
				const idToShow = event.target.parentNode!.parentElement!.dataset[infoKey]
				if (idToShow) {
					const textToShow = document.getElementById(idToShow)
					if (textToShow) {
						eventBus.emit("informationModal", "Toelichting", undefined, textToShow.innerHTML, undefined, idToShow)
					}
				}
			}
		}

		showWaardeopgaveText(event: Event) {
			if (event.target instanceof Element) {
				const textToShow = document.getElementById("_Form_LB0")
				if (textToShow) {
					eventBus.emit("informationModal", "Toelichting", undefined, textToShow.innerHTML, undefined, "_Form_LB0")
				}
			}
		}

		scrollToTop() {
			window.scrollTo({
				top: -40,
				left: 0,
				behavior: "smooth"
			})
		}

		async callWidgetReqEnd() {
			await Vue.nextTick()
			if (this.selectedPolicy) {
				await this.getClaimDocuments(this.selectedPolicy)
				this.$emit("requestSend")
			}
		}

		async beforeMount() {
			let resetWidget = false
			await Vue.nextTick()
			const loadClient = new Promise<any>(resolve => {
				// Use `typeof`, because this works even if the variable really is undefined.
				if (this.annuityExpirationDestination === AnnuityExpirationDestination.OWN_ACCOUNT ||
					this.annuityExpirationDestination === AnnuityExpirationDestination.VALUE_TRANSFER) {
						resetWidget = true
			}
				if (typeof commandclient === "undefined" || resetWidget) {
					const script = document.createElement("script")
					script.src = clientScript
					script.onload = () => {
						resolve(commandclient)
					}
					document.body.appendChild(script)
				} else {
					resolve(commandclient)
				}
			})
			const [client, ssoCredentials] = await Promise.all([
				loadClient,
				fetchSsoCredentials()
			])
			client.setTrackPageFn((pageCode: string) => {
				this.pageIndex = this.widgetPages.indexOf(pageCode)

				if (this.pageIndex === -1) {
					this.widgetPages.push(pageCode)
					this.pageIndex = this.widgetPages.length - 1
				}

				this.$emit('tealiumPageView', { widgetPageIndex: this.pageIndex })
			})
			client.start(
				this.$refs.widget,
				{
					routingUrl: this.routingUrl,
					ssoCredentials
				}
			)

			const doneCallback = async () => {
				this.pageIndex = this.initiatedByAdvisor ? 3 : 4
				await this.callWidgetReqEnd()
			}

			if ((this.selectedWidget === "addAfkoop" || this.selectedWidget === "addExpiratie") && this.selectedPolicy) {
				let claimType = ""

				if (this.selectedWidget === "addAfkoop") {
					claimType = "afkoop"
				} else {
					claimType = "expiratie"
				}

				if (this.annuityExpirationDestination === AnnuityExpirationDestination.OWN_ACCOUNT ||
					this.annuityExpirationDestination === AnnuityExpirationDestination.VALUE_TRANSFER) {

					const lijfrenteExpiratieBestemmingValue = (this.annuityExpirationDestination === AnnuityExpirationDestination.OWN_ACCOUNT) ? "eigenRekening" : "waardeoverdracht"

					client.startAddClaimByPolicies({
						instruction: {
							policyExtId: this.selectedPolicy.policyIdentifier,
							claimtype: claimType,
							lijfrenteExpiratieBestemming : lijfrenteExpiratieBestemmingValue
						},
						fnParams: {
							redirectToThankYouScreenAfterAddClaimFn: doneCallback
						}
					})
				} else {
					client.startAddClaimByPolicies({
						instruction: {
							policyExtId: this.selectedPolicy.policyIdentifier,
							claimtype: claimType
						},
						fnParams: {
							redirectToThankYouScreenAfterAddClaimFn: doneCallback
						}
					})
				}
			} else if (this.selectedWidget === "complete" && this.selectedPolicy && this.selectedPolicy.claim) {
				const lijfrenteExpiratieBestemmingValue = (this.annuityExpirationDestination === AnnuityExpirationDestination.OWN_ACCOUNT) ? "eigenRekening" : "waardeoverdracht"
				client.startResumeIntermediatelySavedClaimReport({
					instruction: {
						claimExtId: this.selectedPolicy.claim.claimNumber,
						lijfrenteExpiratieBestemming: lijfrenteExpiratieBestemmingValue
					},
					fnParams: {
						redirectToThankYouScreenAfterAddClaimFn: doneCallback
					}
				})
			}
			client.show()

			const config = { childList: true, subtree: true }
			const observer = new MutationObserver(this.watchWidgetEvents)
			observer.observe(this.$refs.widget as HTMLElement, config)
		}

		async mounted() {
			await Vue.nextTick()
		}
	}
</script>

<style>
	@import "main.css";
	@import "pages/widget.css";
</style>
