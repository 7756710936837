<template>
	<div class="widget-thankyou">
		<nav class="progress-bar">
			<ul>
				<li :class="{completed: !inPage}">{{ inPage ? 1 : "" }}</li>
				<li :class="{completed: !inPage}">{{ inPage ? 2 : "" }}</li>
				<li :class="{selected: initiatedByAdvisor, completed: !inPage && !initiatedByAdvisor}">{{ inPage ? 3 : "" }}</li>
				<li class="selected" v-if="!initiatedByAdvisor">{{ inPage ? 4 : "" }}</li>
			</ul>
		</nav>
		<h1 v-if="inPage">Bedankt! <span v-if="pendingClaim">U bent bijna klaar.</span></h1>
		<h2 v-else>Bedankt! <span v-if="pendingClaim">U bent bijna klaar.</span></h2>

		<div class="grey-box thankyou-box">
			<p v-if="selectedWidget === 'addAfkoop'">
				Wij hebben het verzoek voor het voortijdig stopzetten van uw verzekering ontvangen.
			</p>
			<p v-if="selectedWidget === 'addExpiratie' || selectedWidget === 'complete'">
				Wij hebben uw verzoek in goede orde ontvangen.
			</p>

			<span v-if="selectedWidget === 'addExpiratie'">
				<p v-if="approved || pendingDone">
					De uitkering maken we binnen 10 werkdagen na de einddatum van uw verzekering over naar het opgegeven bankrekeningnummer. U hoeft niets meer te doen.
				</p>
			</span>
			<span v-else>
				<span v-if="approved">
					<p v-if="activePackageRiskPolicy">
						Wij handelen uw verzoek zo snel mogelijk af.<br><br>Deze verzekering maakt onderdeel uit van een pakket. Alle verzekeringen in een pakket moeten op dezelfde dag stopgezet worden. Regel daarom vandaag ook het stopzetten van de verzekering(en) met polisnummer {{ activePackageRiskPolicy.policyIdentifier }}.
					</p>
					<p v-else>
						Wij handelen uw verzoek zo snel mogelijk af. U hoeft niets meer te doen.
					</p>
				</span>
				<span v-if="pendingDone">
					<p v-if="activePackageRiskPolicy">
						Wij handelen uw verzoek binnen 10 werkdagen af.<br><br>Deze verzekering maakt onderdeel uit van een pakket. Alle verzekeringen in een pakket moeten op dezelfde dag stopgezet worden. Regel daarom vandaag ook het stopzetten van de verzekering(en) met polisnummer {{ activePackageRiskPolicy.policyIdentifier }}.
					</p>
					<p v-else>
						Wij handelen uw verzoek binnen 10 werkdagen af. U hoeft niets meer te doen.
					</p>
				</span>
			</span>

			<span v-if="pendingClaim">
				<documents-table v-if="selectedPolicy && selectedPolicy.claim"
					:data="selectedPolicy.claim.requestedInformation"
					:inPage="inPage"
					@uploadSingleFile="$emit('uploadSingleFile', $event)" />
				<span v-if="selectedWidget === 'addAfkoop'">
					<p>
						Zodra wij alle benodigde documenten hebben ontvangen, gaan we ze beoordelen. Zijn we akkoord? Dan handelen wij uw verzoek binnen 10 werkdagen af.
					</p>
					<p v-if="activePackageRiskPolicy && !(documentsToUpload.length > 0)">
						De verzekering maakt onderdeel uit van een pakket. Alle verzekeringen in een pakket moeten op dezelfde dag stopgezet worden. Regel daarom na het uploaden van de benodigde documenten ook direct het stopzetten van de verzekering(en) met polisnummer {{ activePackageRiskPolicy.policyIdentifier }}.
					</p>
				</span>
				<template v-if="selectedWidget === 'addExpiratie'">
					<p v-if="expirationClaimWithSourceSystemABC">
						Zodra wij alle benodigde documenten hebben ontvangen, gaan we ze beoordelen. Zijn we akkoord? Dan maken we de uitkering van uw ABC Beleggingsplan rond 15 mei over naar het opgegeven bankrekeningnummer.
					</p>
					<p v-else>
						Zodra wij alle benodigde documenten hebben ontvangen, gaan we ze beoordelen. Zijn we akkoord? Dan maken we de uitkering binnen 10 dagen na de einddatum over naar het opgegeven bankrekeningnummer.
					</p>
				</template>
				<template v-if="selectedWidget === 'complete'">
					<p v-if="expirationClaimWithSourceSystemABC">
						Zodra wij alle benodigde documenten hebben ontvangen, gaan we ze beoordelen. Zijn we akkoord? Dan maken we de uitkering van uw ABC Beleggingsplan rond 15 mei over naar het opgegeven bankrekeningnummer. Is deze datum al verstreken? Dan geldt een termijn van maximaal 10 werkdagen.
					</p>
					<p v-else>
						Zodra wij alle benodigde documenten hebben ontvangen, gaan we ze beoordelen. Zijn we akkoord? Dan handelen wij uw verzoek binnen 10 werkdagen af.
					</p>
				</template>
			</span>

			<p v-if="approved || pendingDone">
				Het uitkeringsdocument kunt u in uw Overzicht terugvinden bij "Uw correspondentie". Ook hebben we u een bevestiging per e-mail verstuurd.
			</p>

			<p v-if="pendingDone">
				De status van uw verzoek vindt u in het overzicht van uw verzoeken.
			</p>

			<p>Bedankt voor het jarenlange vertrouwen in a.s.r.</p>
		</div>
		<div class="grey-box">
			<usabilla-feedback v-show="!pendingClaim" event="END_INSURANCE" />
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import { namespace } from "vuex-class"

	import { ClaimDocument, Policy } from "store/policies/types"

	import DocumentsTable from "custom/DocumentsTable.vue"
	import UsabillaFeedback from "custom/UsabillaFeedback.vue"

	import * as rules from "store/rules"

	const routing = namespace("routing")

	@Component({
		components: {
			DocumentsTable,
			UsabillaFeedback
		}
	})
	export default class WidgetThankYou extends Vue {
		@Prop({type: Boolean, default: false}) inPage!: boolean
		@Prop({type: Boolean, required: true}) initiatedByAdvisor!: boolean
		@Prop({type: Object, required: false}) activePackageRiskPolicy!: Policy

		@routing.State selectedWidget?: "addAfkoop" | "addExpiratie" | "complete"
		@routing.Getter approved!: boolean
		@routing.Getter documentsToUpload!: Array<ClaimDocument>
		@routing.Getter pendingClaim!: boolean
		@routing.Getter pendingDone!: boolean
		@routing.Getter selectedPolicy?: Policy

		get expirationClaimWithSourceSystemABC() {
			return this.selectedPolicy && rules.hasExpirationClaim(this.selectedPolicy) && this.selectedPolicy.sourceSystem === "ABC_KAS"
		}
	}
</script>

<style>
	@import "main.css";
	@import "pages/widget.css";
</style>
