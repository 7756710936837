<template>
	<div class="input-box">
		<div v-if="currentEnddate" class="sub-box">
			<label>Huidige einddatum</label>
			<div class="current">{{ currentEnddate }}</div>
		</div>
		<div class="sub-box">
			<date-input v-model="newEndDate"
				@defineValid="valid => validNewEndDate = valid"
				@focus="$emit('focus', { ...$event, label: fieldLabel })"
				@error="$emit('error', { ...$event, componentUid: 'nieuwe einddatum' })"
				:doValidate="true"
				:validateFuture="true"
				:todayAllowed="false"
				:dateToPass="currentEnddate"
				:dateNotToCross="maxEndDate"
				:submitted="submitted">
				<template #label>{{ fieldLabel }} *</template>
			</date-input>
		</div>
		<div class="sub-box info">U kunt uw verzekering maximaal verlengen tot in het jaar waarin de verzekerde 70 wordt. Die einddatum hebben wij alvast voor u ingevuld. Wilt u de verzekering liever iets korter laten doorlopen? Dan kunt u de einddatum aanpassen.</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import DateInput from "leven-shared-components/DateInput.vue"
	import { convertDate } from "leven-shared-components/typings/convert"

	@Component({
		components: {
			DateInput
		}
	})
	export default class PostPoneEndDate extends Vue {
		@Prop({type: Boolean, required: true}) submitted!: boolean
		@Prop({type: Date, required: false}) policyEnddate!: Date | undefined
		@Prop({type: Date, required: false}) insuredDateOfBirth!: Date

		fieldLabel = "Nieuwe einddatum"
		newEndDate: string | undefined = this.maxEndDate
		validNewEndDate = false

		mounted() {
			this.changeEndDate()
		}

		get currentEnddate() {
			return this.policyEnddate ? convertDate(this.policyEnddate) : undefined
		}

		get maxEndDate() {
			const maxEndDateDay = this.policyEnddate!.getDate()
			const maxEndDateYear = this.insuredDateOfBirth.getFullYear() + 70

			return this.insuredDateOfBirth ? convertDate(new Date(maxEndDateYear, 11, maxEndDateDay)) : undefined
		}

		addLeadingZeros(text: string, totalLength: number): string {
			return String(text).padStart(totalLength, "0")
		}

		formatDate(date: string): string {
			const dayFormatted = this.addLeadingZeros(date.split("-")[0], 2)
			const monthFormatted = this.addLeadingZeros(date.split("-")[1], 2)
			const year = date.split("-")[2]

			return `${dayFormatted}-${monthFormatted}-${year}`
		}

		@Watch("newEndDate")
		changeEndDate() {
			let mutation = ""

			if (this.validNewEndDate) {
				const newEndDateFormatted = this.formatDate(this.newEndDate!)

				mutation = `${this.fieldLabel}: ${newEndDateFormatted}`
			}

			this.changeFields(mutation, this.validNewEndDate)
		}

		changeFields(mutation: string, mutationValid: boolean) {
			this.$emit("changedMutationFields", { mutation, mutationValid })
		}
	}
</script>
