<template>
	<div class="content-wrapper">
		<page-header />

		<template v-if="contentPage">
			<div id="main-content" class="container container--detail">
				<div class="pagewrapper" :class="`pagewrapper--${contentPage}`">
					<div v-if="hasHeading" class="pagewrapper__heading">
						<slot name="headercontent"></slot>
						<h1 v-if="hasTitle" :class="{'pagewrapper__title title--small': hasSubTitle, 'pagewrapper__title': !hasSubTitle}">
							<slot name="title"></slot>
						</h1>
						<h1 v-if="hasSubTitle" class="pagewrapper__subtitle">
							<slot name="subtitle"></slot>
						</h1>
					</div>
					<div class="pagewrapper__content">
						<slot></slot>
					</div>
					<div class="pagewrapper__nav">
						<back-button v-if="backLink" :class="{'relative': hasNavigation}" @click="$emit('clicked', $event); resetSelection()" :href="backLink" :text="backText" />
						<div v-if="hasNavigation" class="nav__buttons">
							<slot name="navigation"></slot>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div id="main-content" class="content">
				<slot></slot>
			</div>
		</template>

		<page-footer>
			<template slot="copyright">© a.s.r.</template>
		</page-footer>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"
	import {namespace} from "vuex-class"

	import {StoreMutation} from "lib/types/vuex"
	import {Routing} from "store/routing/types"

	import PageHeader from "custom/PageHeader.vue"
	import PageFooter from "custom/PageFooter.vue"
	import LinkButton from "components/button/LinkButton.vue"
	import BackButton from "./BackButton.vue"

	const routing = namespace("routing")

	@Component({
		components: {
			PageHeader,
			PageFooter,
			LinkButton,
			BackButton
		}
	})
	export default class Page extends Vue {
		@Prop({type: String, required: false }) contentPage?: string
		@Prop({type: String, required: false }) backLink?: string
		@Prop({type: String, required: false }) backText?: string

		@routing.State selectedDocumentId!: Routing["selectedDocumentId"]
		@routing.State selectedProfileItem!: Routing["selectedProfileItem"]
		@routing.Mutation selectDocumentId!: StoreMutation<string | undefined>
		@routing.Mutation selectProfileItem!: StoreMutation<string | undefined>

		hasTitle = false
		hasSubTitle = false
		hasNavigation = false
		hasHeading = false

		mounted() {
			this.hasTitle = !!this.$slots.title
			this.hasSubTitle = !!this.$slots.subtitle
			this.hasNavigation = !!this.$slots.navigation
			this.hasHeading = !!this.$slots.title || !!this.$slots.subtitle
		}

		resetSelection() {
			if (this.selectedDocumentId || this.selectedProfileItem) {
				this.selectProfileItem(undefined)
				this.selectDocumentId(undefined)
			}
		}
	}
</script>

<style>
	@import "main.css";
	@import "custom/pagewrapper.css";
</style>