<template>
	<div v-if="bankStatementsHelpTexts" class="bankstatement-info">
		<p v-if="bankStatementsHelpTexts.generalInstruction" v-html="bankStatementsHelpTexts.generalInstruction"></p>
		<select v-model="bank">
			<option v-for="instruction in bankStatementsHelpTexts.specificInstructionPerBank" 
				:key="instruction.value"
				:value="instruction.value">{{ instruction.label }}
			</option>
		</select>
		<div v-if="selectedInstruction">
			<p v-html="selectedInstruction"></p>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"

	import bankStatementsHelpTexts from "leven-shared-components/data/bankStatementsHelpTexts"

	@Component
	export default class InformationModalBankStatement extends Vue {
		bank: string = ""

		bankStatementsHelpTexts = bankStatementsHelpTexts

		get selectedInstruction() {
			const selectedInstruction = bankStatementsHelpTexts.specificInstructionPerBank.find(item => item.value === this.bank)

			return selectedInstruction ? selectedInstruction.instructionText : undefined
		}
	}
</script>

<style>
	.bankstatement-info select,
	.bankstatement-info select:focus {
		display: block;
		font-size: 16px;
		color: #444;
		line-height: 48px;
		padding: 0 12px;
		width: 100%;
		max-width: 100%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin: 0;
		border: 2px solid #e9e9ea;
		-webkit-box-shadow: 0 1px 0 1px rgb(0 0 0 / 4%);
		box-shadow: 0 1px 0 1px rgb(0 0 0 / 4%);
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		background-color: #fff;
		background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E), -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
		background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E), linear-gradient(180deg, #fff 0, #fff);
		background-repeat: no-repeat, repeat;
		background-position: right 0.7em top 50%, 0 0;
		background-size: 0.65em auto, 100%;
		cursor: pointer;
		font-family: Avenir, sans-serif;
	}

	.bankstatement-info ul {
    list-style: disc !important; /* necessary to override styling on the Axon widget page */
	}
</style>
