<template>
	<div class="box">
		<div class="pagewrapper__heading">
			<h1>Verzekering wijzigen</h1>
		</div>
		<request-loader v-if="!dataLoaded" loadingMessage="Uw gegevens worden geladen" />
		<div v-else class="box-content">
			<p v-html="introText"></p>
			<p><strong>Polisnummer</strong> {{ policyData.policyIdentifier }} <span class="box-product"><strong>Product</strong> {{ policyData.productName }}</span></p>

			<div class="box-mutationgroup">
				<div class="box-mutationlist" v-if="insuranceMutations.length > 1">
					<h2>Verzekering wijzigen</h2>
					<a v-for="mutation in insuranceMutations" :key="mutation.mutationType" href="#"
						@click.prevent="changeMutation(mutation)">{{ mutation.subject }}</a>
				</div>

				<div class="box-mutationlist">
					<h2><span v-if="insuranceMutations.length > 1">{{ titleText }}</span></h2>
					<a v-for="mutation in administrationMutations" :key="mutation.mutationType" href="#"
						@click.prevent="changeMutation(mutation)">{{ mutation.subject }}</a>
					<span v-if="insuranceMutations.length === 1">
						<a v-for="mutation in insuranceMutations" :key="mutation.mutationType" href="#"
						@click.prevent="changeMutation(mutation)">{{ mutation.subject }}</a>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"
	import { Application, MutationGroup, MutationType} from "./typings/enums"
	import { PolicyData, Mutation } from "./typings/interfaces"
	import RequestLoader from "leven-shared-components/RequestLoader.vue"

	@Component({
		components: {
			RequestLoader
		}
	})
	export default class MutationOverview extends Vue {
		@Prop({type: Boolean, required: true}) dataLoaded!: boolean
		@Prop({type: String, required: true}) application!: Application
		@Prop({type: Array, required: true}) mutationList!: Array<Mutation>
		@Prop({type: Object, required: true}) policyData!: PolicyData

		introText = ""
		titleText = ""

		get administrationMutations() {
			return this.mutationList.filter(mutation => mutation.mutationGroup === MutationGroup.ADMINISTRATION)
		}

		get insuranceMutations() {
			if (this.mutationList) {

				let filteredMutationList = this.mutationList.filter(mutation => mutation.mutationGroup === MutationGroup.INSURANCE)

				if (!this.policyData.multipleInsured) {
					filteredMutationList = filteredMutationList.filter(mutation => mutation.mutationType !== MutationType.DELETE_INSURED)
				}

				if (!this.policyData.redeemable) {
					filteredMutationList = filteredMutationList.filter(mutation => mutation.mutationType !== MutationType.END_INSURANCE)
				}

				if (this.policyData.isRecentlyExpiredWithAllowedProduct) {
					filteredMutationList = filteredMutationList.filter(mutation => mutation.mutationType === MutationType.POSTPONE_ENDDATE)
				}

				return filteredMutationList
			}
			return []
		}

		mounted() {
			if (this.application === Application.SELFSERVICE) {
				this.introText = "Hieronder kunt u uw persoonlijke gegevens wijzigen. Of een verzoek indienen om uw verzekering aan te passen. Vervolgens beoordelen wij of uw gewenste wijziging mogelijk is."
				this.titleText = "Wijzig uw gegevens"
			} else {
				this.introText = "<strong>Let op:</strong> Nadat u de wijziging instuurt, beoordelen wij eerst of de gewenste wijziging mogelijk is."
				this.titleText = "Persoonlijke gegevens wijzigen"
			}
		}

		changeMutation(mutation: Mutation) {
			if (mutation.mutationType === MutationType.END_INSURANCE && this.policyData.hasPackageAccrualPolicyToRedeem) {
				this.$emit("showRedeemPackagePolicyModal", mutation)
			} else {
				this.$emit("selectMutation", mutation)
			}
		}

	}
</script>
