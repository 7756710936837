<template>
	<div class="radio-box">
		<label class="input-label"><slot name="label"></slot></label>
		<span :class="{ 'displayBlock' : block}" v-for="(option, index) in options" :key="option.value">
			<input :id="option.value" v-model="internalValue" type="radio" :value="index" @change="onChange" />
			<label :for="option.value">{{ option.label }}</label>
		</span>
		<slot name="validation"></slot>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Emit, Prop, Watch } from "vue-property-decorator"
	import { OptionModel } from "./typings/interfaces"
	import { HTMLEvent } from "./typings/enums"
	import { isEqual } from "lodash-es"

	@Component
	export default class RadioInput<T extends OptionModel> extends Vue {
		@Prop({ type: Array, default: () => [] }) options!: Array<T>
		@Prop({ type: [String, Number, Boolean, Object] }) value?: string | number | boolean | object
		@Prop({ type: Boolean }) block?: boolean

		internalValue: number = -1

		@Watch("options", { immediate: true })
		@Watch("value", { immediate: true })
		valueChanged(): void {
			// this.value can be false, which is why we check like this.
			if (this.value === null || this.value === undefined) {
				return
			}

			this.internalValue = this.options.findIndex(option => isEqual(option.value, this.value))
		}

		@Emit(HTMLEvent.INPUT)
		@Emit(HTMLEvent.CHANGE)
		onChange(): T {
			const selected = this.options[this.internalValue]?.value
			return selected
		}
	}
</script>

<style>
	.displayBlock {
		display: block;
	}
</style>
