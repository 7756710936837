import { Term } from 'leven-shared-components/typings/enums'
import { Claim, ClaimDocument, Premium, Beneficiary, Policy } from 'leven-shared-components/store/policies/types'
export {
    Counselor,
    PolicyOnClaim,
    Claim,
    ClaimDocument,
    Fund,
    Premium,
    BeneficiaryKind,
    Beneficiary,
    CoverageValue,
    CoverageField,
    CoverageData,
    AxonLabel,
    FieldMapping,
    AxonFields,
    AxonCoverage,
    PaymentText,
    Coverage,
    PledgeHolder,
    Policy,
    AllocationFactor,
    AllocationKeyList
} from 'leven-shared-components/store/policies/types'

export interface PolicyState {
    policiesLoaded: boolean
    claimsLoaded: boolean
    policies: Array<Policy>
    claims: Array<Claim>
}

export enum ClaimStatus {
    IN_PROGRESS = -1,
    IN_PROGRESS_ACTION_REQUIRED = -2,
    IN_PROGRESS_APPROVED = -3,
    PAYED_OR_CANCELLED = -4
}

export interface UnfinishedClaim {
    nasmId: string
    damageDate: Date
    policyIdentifier: string
    externalIdentifier: string
}

export interface ClaimStatusStep {
    stepStatusExternalIdentifier: string
    externalStatusName: string
    claimExternalStatusExternalIdentifier: string
    actionCode: string
}

export interface Premiums {
    current: Array<Premium>
    future: Array<Premium>
    multiplePremiums: boolean
    currentFrequency?: Term
    currentTotal?: number
}

export type Beneficiaries = Map<number, ReadonlyArray<Beneficiary>> | null

// LEAVE THE : REQUIRED FOR AXON QUERY
export enum CoverageTypeName {
    LIFE_COVERAGE = 'Levendekking:',
    DEATH_COVERAGE = 'Overlijdensdekking:',
    EXTRA_COVERAGE = 'Aanvullende dekking:'
}

export interface UploadInfo {
    toUpload: Array<ClaimDocument>
    claimID: string
    fileInfo: any
    policyID: string
    customerExtId: string
}

export interface AccordionData {
    id: string
    title: string
}

export interface Advisor {
    name: string
    phoneNumber: string | undefined
    emailAddress: string | undefined
    policyNumbers: Array<string>
}
