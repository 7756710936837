<template>
	<div role="status">
		<div v-if="isLoading !== false" class="request-loader">
			<div><img src="images/ajax-loader.gif" /></div>
			<div v-if="loadingMessage">{{ loadingMessage }}</div>
		</div>
		<div v-else-if="finishMessage" class="invisible">{{ finishMessage }}</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"

	@Component
	export default class RequestLoader extends Vue {
		@Prop({type: Boolean, required: false}) isLoading?: boolean
		@Prop({type: String, required: false}) loadingMessage?: string
		@Prop({type: String, required: false}) finishMessage?: string
	}
</script>

<style>
	@import "custom/request-holder.css";
</style>
