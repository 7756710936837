<template>
	<div>
		<label class="input-label"><slot name="label"></slot></label>
		<input
			class="date"
			placeholder="dd-mm-jjjj"
			type="text" :value="value"
			@focus="$emit('focus', { isRequired: doValidate })"
			@input="validate(formatDate($event.target.value));$emit('input', formatDate($event.target.value))" 
		/>
		<accessible-error
			:show-error="submitted && !validDate"
			:error-message="validationMessage"
		/>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { validateDate, formatDate } from "./utils/validations"
	import AccessibleError from "./AccessibleError.vue"

	@Component({
		components: {
			AccessibleError
		}
	})
	export default class DateInput extends Vue {
		@Prop({type: String, required: false}) value!: string
		@Prop({type: Boolean, required: true}) doValidate!: boolean
		@Prop({type: Boolean, required: true}) validateFuture!: boolean
		@Prop({type: Boolean, required: true}) todayAllowed!: boolean
		@Prop({type: String, required: false}) dateToPass!: string
		@Prop({type: String, required: false}) dateNotToCross!: string
		@Prop({type: Boolean, required: true}) submitted!: boolean

		formatDate = formatDate

		validDate = false
		validationMessage = ""

		@Watch("doValidate", { immediate: true })
		@Watch("dateToPass", { immediate: true })
		changeValidate() {
			this.validate(this.value)
		}

		validate(value: string) {
			if (this.doValidate) {
				const evaluation = validateDate(value, this.validateFuture, this.todayAllowed, this.dateToPass, this.dateNotToCross)
				this.validationMessage = evaluation.message

				this.changeValid(evaluation.valid)
				return evaluation.valid
			}
			this.changeValid(true)
			return true
		}

		changeValid(valid: boolean) {
			this.validDate = valid
			this.$emit("defineValid", valid)
		}

		@Watch("submitted")
		@Watch("validDate")
		checkErrors() {
			if (this.submitted && !this.validDate) {
				this.$emit('error', { errorMessage: this.validationMessage })
			}
		}
	}
</script>
