<template>
    <div v-show="isActive" :id="`tabpanel.${id}`" role="tabpanel">
        <slot />
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import { Prop } from 'vue-property-decorator'

    @Component
    export default class TabPanel extends Vue {
        @Prop({ type: [String, Number], required: true }) id!: string | number
        @Prop({ type: [String, Number], required: false }) activeId?: string | number

        get isActive(): boolean {
            return this.activeId === this.id
        }
    }
</script>
