<template>
	<accordion-item 
		:id="accordionData.id" 
		:clicked="accordionData.id" 
		:tealiumTitle="accordionData.title"
		@toggle="(isOpen) => $emit('toggleAccordion', { isExpanded: isOpen, textContent: accordionData.title })"
	>
		<template #title>
			<h3>{{ accordionData.title }}</h3>
		</template>
		<policy-detail-item>
			<template #title>
				<information-link
					:id="`${accordionData.id}.fiscaal-regime`"
					label="Fiscaal regime" 
					text="De belastingregels die gelden voor de verzekering." 
					@click="$emit('toggleTooltip', { textContent: 'Fiscaal regime', componentUid: accordionData.title })"
				/>
			</template>
			<template #value>{{ clause.title }}</template>
		</policy-detail-item>
		<policy-detail-item v-if="showFiscalValue">
			<template #title>
				<information-link 
					:id="`${accordionData.id}.fiscale-waarde`"
					label="Fiscale waarde"
					text="De waarde die u nodig heeft voor uw jaarlijkse belastingaangifte." 
					@click="$emit('toggleTooltip', { textContent: 'Fiscale waarde', componentUid: accordionData.title })"
				 />
			</template>
			<template #value>De fiscale waarde staat in de Fiscale jaaropgave die u jaarlijks vóór de derde week van maart van ons ontvangt. Deze brief vindt u onder <a href="communicationdetail.html">'Uw correspondentie'</a>.</template>
		</policy-detail-item>
		<policy-detail-item v-else>
			<template #title>
				Fiscale waarde
			</template>
			<template #value>De waarde van uw verzekering hoeft u niet op te geven bij de belastingaangifte. Daarom ontvangt u van ons geen jaarlijkse brief met de fiscale waarde.</template>
		</policy-detail-item>
		<policy-detail-item v-if="texts" :fullWidth="true">
			<template #value>
				<span v-html="texts.introduction" class="introduction"></span>
				<span v-if="texts.duringTerm !== '' && texts.atPayment !== ''">
					<TabsList>
						<TabComponent v-for="tab in tabs" :key="tab.id" :id="tab.id" :active-id="selected" @toggle="selectTab(tab, $event)">
							{{ tab.title }}
						</TabComponent>
						<template #content>
							<TabPanel v-for="tab in tabs" :key="tab.id" :id="tab.id" :active-id="selected">
								<div v-html="texts[tab.id]" />
							</TabPanel>
						</template>
					</TabsList>
				</span>
			</template>
		</policy-detail-item>
	</accordion-item>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"
	import {AccordionData} from "store/policies/types"
	import {Clause, ClauseCode} from "leven-shared-components/typings/interfaces"
	import fiscalTexts, {FiscalTexts} from "store/policies/fiscaltexts"
	import AccordionItem from "custom/AccordionItem.vue"
	import PolicyDetailItem from "custom/PolicyDetailItem.vue"
	import InformationLink from "custom/InformationLink.vue"
	import TabComponent from "./TabComponent.vue"
	import TabsList from "./TabsList.vue"
	import TabPanel from "./TabPanel.vue"

	interface Tab {
		id: "duringTerm" | "atPayment",
		title: string
	}

	@Component({
		components: {
			AccordionItem,
			PolicyDetailItem,
			InformationLink,
			TabsList,
			TabComponent,
			TabPanel
		}
	})
	export default class PolicyTaxRules extends Vue {
		@Prop({type: Object, required: true}) clause!: Clause

		accordionData: AccordionData = {
			id: "policy-tax-rules",
			title: "Belastingregels voor uw verzekering"
		}

		texts: FiscalTexts | null | undefined = null

		tabs: Array<Tab> = [
			{
				id: "duringTerm",
				title: "Tijdens de looptijd"
			},
			{
				id: "atPayment",
				title: "Bij de uitkering"
			}
		]

		selected: Tab["id"] = "duringTerm"

		async mounted() {
			await Vue.nextTick()
			this.setTexts(this.clause.code)
		}

		selectTab(tab: Tab, event: MouseEvent | KeyboardEvent) {
			this.selected = tab.id
			this.$emit('tabClicked', { eventType: 'internal', event, componentUid: this.accordionData.title })
		}

		setTexts(clauseCode: ClauseCode) {
			this.texts = fiscalTexts.find(text => text.clauses.includes(clauseCode))
		}

		get showFiscalValue() {
			const clauses: Array<ClauseCode> = [ClauseCode.KAP_Box3,
												ClauseCode.KAP_pBHW,
												ClauseCode.KAP_BHW_BV,
												ClauseCode.KAP_BHW,
												ClauseCode.KAP_LYF_PU,
												ClauseCode.KAP_ERFRNT]

			return clauses.find(clauseCode => clauseCode === this.clause.code)
		}

	}
</script>

<style>
	@import "custom/message-list.css";

	.tabs-content {
		background-color: white;
		padding: 15px;
		height: fit-content;
		box-shadow: 0 5px 20px rgba(0,0,0,.05), 0 2px 5px rgba(0,0,0,.1);
	}

	.introduction br, .tabs-content br {
		display: block;
		content: "";
		margin-top: 14px;
	}
</style>
