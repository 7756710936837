<template>
	<modal event="redeemPackagePolicyModal" class="modal--padded">
		<template slot="header">Stopzetten pakket van levensverzekeringen</template>
		<template slot="content">
			<p>
				Uw verzekering maakt deel uit van een pakket. Dit pakket zorgt er voor dat uw levensverzekeringen één geheel vormen. U kunt deze overlijdensrisicoverzekering stopzetten nadat u de verzekering met polisnummer {{ policyToRedeem.policyIdentifier }} heeft stopgezet. Regel <a href="#" @click.prevent="startWidgetWithRedemptionOfPackagePolicy">hier</a> het stopzetten van uw verzekering met polisnummer {{ policyToRedeem.policyIdentifier }}.
			</p>
		</template>
	</modal>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import Modal from "components/modal/Modal.vue"
	import { Prop } from "vue-property-decorator"
	import { Policy } from "store/policies/types"

	@Component({
		components: {
			Modal
		}
	})
	export default class RedeemPackagePolicyModal extends Vue {
		@Prop({type: Object, required: true}) policyToRedeem!: Policy

		startWidgetWithRedemptionOfPackagePolicy() {
			this.$emit("startWidgetWithRedemptionOfPackagePolicy")
		}
	}
</script>
