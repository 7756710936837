import {Policy, Claim} from "store/policies/types"

export enum AnnuityExpirationDestination {
	UNDEFINED = "UNDEFINED",
	VALUE_TRANSFER = "VALUE_TRANSFER",
	OWN_ACCOUNT = "OWN_ACCOUNT",
	DEFER_ANNUITY = "DEFER_ANNUITY"
}
export interface Routing {
	selectedDocumentId?: string | number
	selectedProfileItem?: string
	selectedWidget?: string
	selectedPolicy?: Policy
	selectedClaim?: Claim
	changeCommunicationPreferenceClicked: boolean
	communicationPreferenceAcquired: boolean
	payoutFormBeneficiaryConsentSubmitted: boolean
	payoutToPledgeHolderRequested: boolean,
	annuityExpirationDestination: AnnuityExpirationDestination
}
