<template>
	<span>
		{{ label }}
		<span
			:id="uuid"
			role="tooltip"
			tabindex="0"
			:aria-expanded="isOpen ? 'true' : 'false'"
			@click.stop="showInformationText"
			@keydown.enter.stop.prevent="showInformationText"
		>
			<information-image :aria-label="`Klik voor meer informatie over ${label}`" />
		</span>
	</span>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"
	import eventBus from "lib/vue/eventBus"
	import InformationImage from "leven-shared-components/InformationImage.vue"

	@Component({
		components: {
			InformationImage
		}
	})
	export default class InformationLink extends Vue {
		@Prop({type: String, required: true}) id!: string
		@Prop({type: String, required: true}) label!: string
		@Prop({type: String, required: true}) text!: string
		@Prop({type: String, required: false}) infoComponent!: string

		uuid = `information-link.${this.id}`
		isOpen = false

		showInformationText() {
			this.isOpen = !this.isOpen
			eventBus.emit("informationModal", "Toelichting", undefined, this.text, this.infoComponent, this.uuid)

			this.$emit('click')
		}
	}
</script>
