<template>
	<div>
		<span v-if="isMoney">&euro; </span>
		<input 
			class="decimal" 
			:readOnly="readonly" 
			:placeholder="placeholder" 
			:value="value" 
			@keydown="helpMessage = ''" 
			@keypress="helpMessage = allowDecimals($event, beforeComma, afterComma)"
			@input="validate($event.target.value);$emit('input', formatDecimals($event.target.value, beforeComma, afterComma))" 
			@change="$emit('input', addZeros($event.target.value, afterComma))" 
			@focus="$emit('focus', { isReadOnly: $event.target.readOnly, isRequired: doValidate })" 
		/>
		<span v-if="!isMoney"> %</span>
		<accessible-error
			:show-error="submitted && !validNumber"
			:error-message="isMoney ? moneyErrorMessage : percentageErrorMessage"
			:help-message="helpMessage"
		/>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { NumberType } from "./typings/enums"
	import { allowDecimals, formatDecimals, addZeros } from "./utils/validations"
	import { toNumber } from "lodash-es"
	import AccessibleError from "./AccessibleError.vue"

	@Component({
		components: {
			AccessibleError
		}
	})
	export default class DecimalInput extends Vue {
		@Prop({type: String, required: false}) value!: string
		@Prop({type: Number, required: true}) beforeComma!: number
		@Prop({type: Number, required: true}) afterComma!: number
		@Prop({type: Number, required: true}) minimumNumber!: number
		@Prop({type: String, required: true}) numberType!: NumberType
		@Prop({type: Boolean, required: true}) doValidate!: boolean
		@Prop({type: Boolean, required: true}) submitted!: boolean
		@Prop({type: String, required: true}) placeholder!: string
		@Prop({type: Boolean, required: false, default: false}) readonly?: boolean

		allowDecimals = allowDecimals
		formatDecimals = formatDecimals
		addZeros = addZeros

		moneyErrorMessage = `Vul a.u.b. minimaal een bedrag van € ${this.minimumNumber},- in.`
		percentageErrorMessage = `Vul a.u.b. een percentage hoger dan ${this.minimumNumber}% in.`

		helpMessage = ""
		validNumber = false

		@Watch("doValidate", {immediate: true})
		changeValidate() {
			this.validate(this.value)
		}

		get isMoney() {
			return this.numberType === NumberType.MONEY
		}

		validate(value: string | undefined) {
			if (this.doValidate) {
				if (value) {
					const a = value.replace(",", ".")
					const amount = toNumber(a)
					if (amount < this.minimumNumber) {
						this.changeValid(false)
						return false
					}
				} else {
					this.changeValid(false)
					return false
				}
			}
			this.changeValid(true)
			return false
		}

		changeValid(valid: boolean) {
			this.validNumber = valid
			this.$emit("defineValid", valid)
		}

		@Watch("submitted")
		@Watch("validNumber")
		checkErrors() {
			if (this.submitted && !this.validNumber) {
				this.$emit('error', { errorMessage: this.isMoney ? this.moneyErrorMessage : this.percentageErrorMessage })
			}
		}
	}
</script>
