import { ProductCode } from "leven-shared-components/typings/products"
import { Term } from "leven-shared-components/typings/enums"

export interface PolicyOnClaim {
	productName: string
	policyIdentifier: string
	packagePolicyIdentifier?: string
	damageCause: string
}

export interface Counselor {
	name: string
	phoneNumber?: string
	emailAddress?: string
	address: ReadonlyArray<string>
}

export interface Claim {
	claimNumber: string
	policies?: Array<string> | null
	listOfMyPolicies: Array<PolicyOnClaim>
	damageDate: Date
	statusId: number
	mustBeCompleted: boolean
	identifierForCompletion?: string | null
	requestedInformation: Array<ClaimDocument>
	externalStatusName?: string
	hasBeenCancelled?: boolean
	hasOutstandingRequestedInformation?: boolean
}

export interface ClaimDocument {
	identifier: string
	name?: string
	statusName?: string
	statusId?: string
	typeExternalIdentifier?: string
	changeToken?: string
}

export enum PolicyStatus {
	ACTIVE = "Actief",
	EXPIRED = "Beëindigd",
	FUTURE = "Toekomstig"
}

export interface Fund {
	identifier: string
	name: string
	units: number
	balance: number
	guaranteeValue?: number
}

export enum ClauseCode {
	LYF_ALIMTT = "LYF_ALIMTT",
	KAP_ERFRNT = "KAP_ERFRNT",
	GDHDRK_NW = "GDHDRK_NW",
	GDHDRK = "GDHDRK",
	KAP_BOX1 = "KAP_BOX1",
	KEW = "KEW",
	KAP_Box3 = "KAP_Box3",
	KAP_LYF = "KAP_LYF",
	KAP_pBHW = "KAP_pBHW",
	KAP_BHW_BV = "KAP_BHW_BV",
	KAP_BHW = "KAP_BHW",
	LEVENSLOOP = "LEVENSLOOP",
	LYF_BHW = "LYF_BHW",
	LYF_IB2001 = "LYF_IB2001",
	KAP_LYF_PU = "KAP_LYF_PU",
	LYF_INVKND = "LYF_INVKND",
	LYF_NETTO = "LYF_NETTO",
	ODGRSV_NW = "ODGRSV_NW",
	ODGRSV_FOR = "ODGRSV_FOR",
	PW = "PW",
	STW_IB2001 = "STW_IB2001",
	STKG_WINST = "STKG_WINST",
	LYF_SALDO = "LYF_SALDO"
}

export enum BeneficiaryKind {
	NVT = "N.v.t.",
	WHILEALIVE = "Leven",
	DEATH = "Overlijden"
}

export interface Beneficiary {
	sequence: number
	name?: string | null
	type?: string | null
	kind: BeneficiaryKind
	percentage: number
	customerExternalId?: string | null
}

export interface Clause {
	code: ClauseCode
	nameAxon: string
	title: string
}

export interface Premium {
	amount: number
	paymentFrequency?: Term
	dateStart?: Date
	dateEnd?: Date
}

export enum CoverageTypeName {
	LIFE_COVERAGE = "Levendekking:",
	DEATH_COVERAGE = "Overlijdensdekking:",
	EXTRA_COVERAGE = "Aanvullende dekking:"
}

export interface PaymentText {
	Ingangsdatum: string
	Einddatum: string
	["Periodieke uitkering"]: string
	["Overgang bij overlijden"]: string
	Ontvanger: string
}

export interface AllocationFactor {
	allocationKeyRef?: any
	allocationKeyFundKey: string
	allocationKeyFundSequenceNr: number
	allocationKeyPercentage: number
	allocatiallocationKey?: any
}

export interface AllocationKeyList {
	type: string
	transactionCategory: string
	allocationFactorList: Array<AllocationFactor>
}

export interface Coverage {
	coverageTypeName: CoverageTypeName
	name: string
	text: string | ((data: CoverageData) => string)
	paymentText?: PaymentText
}

export interface CoverageData {
	insuredAmount?: string
	interestRate?: number
	frequency?: string
	installmentAmount?: number
	endDateInterest?: number
	startDate?: string
	endDate?: string
	changeAmountPerTerm?: number
	insuredPercentage?: number
	receiver?: string
	insuredAmountEnddate?: string
	survivor?: string
	survivorInterest?: string
}

export interface PledgeHolder {
	name: string
	externalLendingNumber: string
	interestPercentage: number
}

export interface Policy {
	policyIdentifier: string
	policyVersionExtId: string
	packageNumber?: string
	product: string
	productId: ProductCode
	status: PolicyStatus
	statusIdentifier: string
	startDate: Date
	startDatePolicyVersion: Date
	endDate?: Date
	counselor: Counselor
	targetCapital?: number
	maturityBenefit?: number
	calculatedMaturityBenefit?: number
	interestPercentage?: number
	funds: Array<Fund>,
	claim?: Omit<Claim, "policies">
	fixedRateFundAccountTotal?: number
	packagePolicy: boolean
	clause?: Clause | null
	premiums?: Array<Premium>
	policyHolderExtId: string
	policyHolderFullName: string
	coPolicyHolderExtId?: string
	coPolicyHolderFullName?: string
	currentUserIsCoPolicyHolder: boolean
	insuredExtId: string
	insuredFullName: string
	insuredDateOfBirth: Date
	coInsuredExtId?: string
	coInsuredFullName?: string
	coInsuredDateOfBirth?: Date
	beneficiaries: Array<Beneficiary>
	coverages?: Array<Coverage>
	pledgeHolders?: Array<PledgeHolder>
	employerId?: string
	premiumIncreaseNotAllowed: boolean
	extensionNotAllowed: boolean
	hasWisselDatumClause: boolean
	isPolicyWithSavingsValue: boolean
	sourceSystem?: string
	costAndProvisionVariant?: string
	policyHolderMortalityBase?: string
	coPolicyHolderMortalityBase?: string
}

export enum AxonLabel {
	INSUREDAMOUNT = "Verzekerd bedrag",
	INTERESTRATE = "Rentepercentage",
	FREQUENCY = "Frequentie",
	INSTALLMENTAMOUNT = "Termijnbedrag",
	ENDDATEINTEREST = "Einddatum rente",
	STARTDATE = "Ingangsdatum",
	ENDDATE = "Einddatum",
	CHANGEAMOUNTPERTERM = "Bedrag verandering per termijn",
	INSUREDPERCENTAGE = "Verzekerd percentage",
	RECEIVER = "Ontvanger",
	INSUREDAMOUNTENDDATE = "Verzekerd bedrag op einddatum",
	SURVIVOR = "Overlevende",
	SURVIVORINTEREST = "Overlevingsrente"
}
export interface FieldMapping {
	axonLabel: AxonLabel
	targetField: keyof CoverageData
}

export interface AxonFields {
	insuredAmount: FieldMapping
	interestRate: FieldMapping
	frequency: FieldMapping
	installmentAmount: FieldMapping
	endDateInterest: FieldMapping
	startDate: FieldMapping
	endDate: FieldMapping
	changeAmountPerTerm: FieldMapping
	insuredPercentage: FieldMapping
	receiver: FieldMapping
	insuredAmountEnddate: FieldMapping
	survivor: FieldMapping
	survivorInterest: FieldMapping
}
export interface CoverageValue {
	typeOfValue?: string
	value: number | string
}

export interface CoverageField {
	labelText: string
	value: CoverageValue
}

export interface AxonCoverage {
	coverageTypeName: CoverageTypeName
	name: string
	coverageFieldList: Array<CoverageField>
}
