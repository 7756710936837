<template>
	<footer class="footer footer--primary">
		<ul class="footer__list">
			<li>
				<a :href="`${publicSiteUrl}/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/54490.pdf`" target="_blank">gebruikersvoorwaarden</a>
			</li>
			<li>
				<a :href="`${publicCorporateUrl}/privacyverklaring`" target="_blank">privacyverklaring</a>
			</li>
			<li>
				<a :href="`${publicSiteUrl}/disclaimer`" target="_blank">disclaimer</a>
			</li>
			<li>
				<a :href="`${publicSiteUrl}/cookieverklaring`" target="_blank">cookieverklaring</a>
			</li>
			<li>
				<a :href="`${publicSiteUrl}/fraudebeleid`" target="_blank">fraudebeleid</a>
			</li>
			<li>
				<a :href="`${publicCorporateUrl}/over-asr/bestuur-en-organisatie/beleid-en-richtlijnen/beleggingsbeleid`" target="_blank">beleggingsbeleid</a>
			</li>
			<li>
				<a :href="`${publicCorporateUrl}/meldpunt-digitale-kwetsbaarheden`" target="_blank">meldpunt digitale kwetsbaarheden</a>
			</li>
			<li class="footer__copyright">
				<slot name="copyright"></slot>
			</li>
		</ul>
	</footer>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { publicSiteUrl, publicCorporateUrl } from "api/settings"

	@Component
	export default class PageFooter extends Vue {
		publicSiteUrl = ""
		publicCorporateUrl = ""

		async mounted() {
			await this.$nextTick()

			this.publicSiteUrl = publicSiteUrl
			this.publicCorporateUrl = publicCorporateUrl
		}
	}
</script>

<style>
	@import "custom/pagefooter.css";
</style>
