import {Message, FAQ} from "api/models/communications"

export interface CommunicationState {
	customerLoaded: boolean
	policyLoaded: boolean
	policyAegonLoaded: boolean
	claimLoaded: boolean
	customer: Array<CustomerDocument>
	policy: Array<PolicyDocument>
	policyAegon: Array<PolicyDocumentAegon>
	claim: Array<ClaimDocument>
	pollClaim: PollClaim
	messagesLoaded: boolean
	messages: Array<Message>
	generalFaqsLoaded: boolean
	generalFaqs: Array<FAQ>
	policyFaqsLoaded: boolean
	policyFaqs: Array<FaqsPerPolicy>
}

export interface FaqsPerPolicy {
	policyID: string
	productName: string
	faqs: Array<FAQ>
}

export interface CommunicationDocument {
	externalIdentifier: string
	communicationId: number
	sendDate: Date
	subject?: string
	content?: string
	attachments?: Array<Attachment>
}

export interface PolicyDocumentAegon {
	externalIdentifier: string
	policyIdentifier: string
	communicationIdentifier: number
	date: Date
	subject: string
}

export interface Attachment {
	externalIdentifier: string
	category: string
	description?: string
	type: string
	hasPdfContent: boolean
	communicationExtId: string
	fileName?: string
}

export type CustomerDocument = CommunicationDocument

export interface PolicyDocument extends CommunicationDocument {
	policyIdentifier: string
}

export interface ClaimDocument extends CommunicationDocument {
	claimIdentifier: string
}

// Interface needed for mapping responses to documents.
export interface Communication<T> {
	documents: Array<T>
	identifier: string
}
export interface PollClaim {
	poll: boolean
	numberOfDocuments: number | undefined
}

export const isPolicyDocument = (document: any): document is PolicyDocument => !!document.policyIdentifier

export const isClaimDocument = (document: any): document is ClaimDocument => !!document.claimIdentifier
