<template>
	<container tag="ul" child-tag="li" :child-class="childClass" :items="items" class="list">
		<slot v-if="typeof this.items === 'undefined'"></slot>
		<template slot-scope="item">
			<slot v-bind="item"></slot>
		</template>
	</container>
</template>


<script lang="ts">
	import { Component, Prop, Vue } from "vue-property-decorator"
	import Container from "components/generic/Container.vue"

	@Component({
		components: {
			Container
		}
	})
	export default class List extends Vue {
		@Prop({ type: Array, required: false }) items!: Array<any>
		@Prop({ type: String, required: false}) childClass!: string
	}
</script>


<style>
	@import "generic/list.css";
</style>