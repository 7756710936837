<template>
	<div class="policydetail__item row">
		<div :class="[fullWidth ? 'col-lg-12 col-md-12 col-xs-12' : 'col-lg-4 col-md-4 col-xs-12']">
			<slot name="title"></slot>
		</div>
		<div :class="[fullWidth ? 'col-lg-12 col-md-12 col-xs-12' : 'col-lg-8 col-md-8 col-xs-12']">
			<slot name="value"></slot>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"

	@Component
	export default class PolicyIntro extends Vue {
		@Prop({default: false, type: Boolean}) fullWidth!: boolean
	}
</script>
