<template>
	<div class="input-box">
		<div v-if="policyData.currentPremiumFrequency" class="sub-box">
			<label>{{ label }}</label>
			<div class="current">{{ capitalize(policyData.currentPremiumFrequency) }}</div>
		</div>
		<div class="sub-box">
			<select-input v-model="selectedPaymentTerm"
				:options="paymentTerms.filter(term => term !== policyData.currentPremiumFrequency)"
				:submitted="submitted"
				@open="$emit('open', { ...$event, label })"
				@click="$emit('click', { ...$event, label })"
				@error="$emit('error', { ...$event, componentUid: 'nieuwe betaaltermijn'})"
				>
				<template #label>{{ fieldLabel }} *</template>
			</select-input>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { paymentTerms } from "leven-shared-components/data/defaults"
	import { Term } from "leven-shared-components/typings/enums"
	import { PolicyData } from "leven-shared-components/typings/interfaces"
	import SelectInput from "leven-shared-components/SelectInput.vue"
	import capitalize from "lib/string/capitalize"

	@Component({
		components: {
			SelectInput
		}
	})
	export default class PaymentTerm extends Vue {
		@Prop({type: Boolean, required: true}) submitted!: boolean
		@Prop({type: Object, required: true}) policyData!: PolicyData

		capitalize = capitalize

		label = "Huidige betaaltermijn"
		fieldLabel = "Nieuwe betaaltermijn"
		paymentTerms = paymentTerms

		selectedPaymentTerm: Term | null = null

		mounted() {
			this.changeFields("", false)
		}

		@Watch("selectedPaymentTerm")
		changeSelectedPaymentTerm() {

			const validSelectedPaymentTerm = this.selectedPaymentTerm !== null

			let mutation = ""

			if (validSelectedPaymentTerm) {
				mutation = `${this.fieldLabel}: ${this.selectedPaymentTerm}`
			}

			this.changeFields(mutation, validSelectedPaymentTerm)
		}

		changeFields(mutation: string, mutationValid: boolean) {
			this.$emit("changedMutationFields", { mutation, mutationValid })
		}

	}
</script>
