<template>
	<span class="informationI">
			<svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-879.000000, -771.000000)" fill="#000000">
				<path d="M887,771 C884.8784,771 882.843733,771.843058 881.343111,773.343111 C879.843111,774.843804 879,776.878578 879,779 C879,781.121422 879.843058,783.156267 881.343111,784.656889 C882.843804,786.156889 884.878578,787 887,787 C889.121422,787 891.156267,786.156942 892.656889,784.656889 C894.156889,783.156196 895,781.121422 895,779 C895,776.878578 894.156942,774.843733 892.656889,773.343111 C891.156196,771.843111 889.121422,771 887,771 Z M887.028473,784.190933 C886.134731,784.62288 884.753451,784.251349 884.994517,783.351342 L886.297291,778.48752 C886.752846,776.786133 885.499371,777.343769 884.937575,777.590293 L884.93827,777.590988 C885.281328,777.074331 885.757026,776.659735 886.314661,776.389602 C887.208404,775.957655 888.589684,776.329186 888.348617,777.229193 L887.045844,782.092302 C886.590288,783.793689 887.843764,783.236053 888.405559,782.989529 C888.061808,783.505493 887.58611,783.920782 887.028475,784.190916 L887.028473,784.190933 Z M889.035406,774.669333 C888.948601,774.992942 888.675691,775.23184 888.343744,775.275591 C888.011797,775.31934 887.686108,775.158924 887.518748,774.86864 C887.351387,774.579058 887.374999,774.216551 887.579164,773.951271 C887.782631,773.685298 888.126382,773.56864 888.449991,773.655431 C888.891662,773.773486 889.153458,774.227662 889.035413,774.669316 L889.035406,774.669333 Z" id="info-icon"></path>
			</g>
		</g>
	</svg>
	</span>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class InformationImage extends Vue {}
</script>

<style>
	span.informationI {
		margin: 0 5px;
		display: inline-block;
		cursor: pointer;
		width: 16px;
		height: 16px;
		position: relative;
	}

	span.informationI svg {
		position: absolute;
		top: 3px;
	}
</style>
