<template>
    <span
        class="error"
        role="alert"
    >
        <slot v-if="$slots.default && showError" />
        <template v-else>
            <p v-if="helpMessage">{{ helpMessage }}</p>
            <p v-if="showError">{{ errorMessage }}</p>
        </template>
    </span>
</template>

<script lang="ts">
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import { Prop } from 'vue-property-decorator'

    @Component
    export default class AccessibleError extends Vue {
        @Prop({ type: Boolean, required: true }) showError!: boolean
        @Prop({ type: String, required: false }) errorMessage?: string
        @Prop({ type: String, required: false }) helpMessage?: string
    }
</script>
