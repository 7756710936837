<template>
	<header class="page-header">
		<div>
			<div class="skip-link">
				<a :href="`${pathname}#main-content`" target="_self">Sla de navigatie over en ga direct naar de inhoud.</a>
			</div>
			<div class="container" role="menubar">
				<a class="logo" href="index.html" role="menuitem">
					<icon-asr-logo />
				</a>
				<a class="title" href="index.html" role="menuitem">
					Uw levensverzekeringen
				</a>
				<a v-if="impersonation" href="#" class="impersonation-close" role="menuitem" @click.prevent="closeWindow">sluiten</a>
				<div v-if="customer.loaded" id="navigation-menu" class="page-header__accordion">
					<accordion-list class="accordion-list--chevron">
						<accordion-item v-if="!toggleFocus" id="menu" clicked="menu" role="menuitem" aria-haspopup="true">
							<template #title>{{ customer.nameForDisplay }}</template>
							<div role="menu">
								<a href="index.html" role="menuitem">Overzicht</a>
								<a href="profile.html" role="menuitem">Uw profiel pagina</a>
								<a href="downloads.html" role="menuitem">Downloads</a>
								<protected-button :protect="impersonation" childClass="link-account--icon" title="Account" role="menuitem">
									<template #button>
										<a href="#" class="link-account--icon" @click.prevent="goToMyAccount">Account</a>
									</template>
								</protected-button>
								<protected-button :protect="impersonation" id="logout-button" title="Uitloggen" role="menuitem">
									<template #button>
										<a id="logout-button" :href="logoutUrl" @click.prevent="logout">Uitloggen</a>
									</template>
								</protected-button>
							</div>
						</accordion-item>
					</accordion-list>
				</div>
			</div>
		</div>
	</header>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { State } from "vuex-class"

	import { IconAsrLogo } from "custom/icons"
	import AccordionList from "custom/AccordionList.vue"
	import AccordionItem from "custom/AccordionItem.vue"

	import { CustomerState } from "store/customer/types"

	import { iWelcomeLogoutUrl, myAccountUrl } from "api/settings"
	import { session } from "api/request"

	import ProtectedButton from "leven-shared-components/ProtectedButton.vue"

	@Component({
		components: {
			IconAsrLogo,
			AccordionList,
			AccordionItem,
			ProtectedButton
		}
	})
	export default class PageHeader extends Vue {
		@State customer!: CustomerState
		@State impersonation!: boolean

		logoutUrl = iWelcomeLogoutUrl
		myAccountUrl = myAccountUrl
		toggleFocus = false
		pathname = ""

		async logout() {
			await session.logout()
		}

		closeWindow() {
			window.close()
		}

		goToMyAccount() {
			window.location.href = myAccountUrl
		}

		toggleMenuFocus() {
			this.toggleFocus = true
			setTimeout(() => {
				this.toggleFocus = false
			})
		}

		tabClicked(event: KeyboardEvent) {
			if (document.activeElement?.id === 'logout-button' && event.key === 'Tab' && !event.shiftKey 
			|| document.activeElement?.id === 'accordionitem.menu' && event.key === 'Tab' && event.shiftKey) {
				this.toggleMenuFocus()
			}
		}

		mounted() {
			this.pathname = window.location.pathname
			addEventListener('keydown' , this.tabClicked)
		}

		unmounted() {
			removeEventListener('keydown' , this.tabClicked)
		}
	}
</script>

<style>
	@import "custom/pageheader.css";
</style>