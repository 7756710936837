<template>
	<div :class="{'is-visible': visible, loaded}" class="modal">
		<div 
			ref="modal__box"
			class="modal__box"
			tabindex="0"
			role="dialog"
			aria-modal="true"
			aria-labelledby="modal__title"
		>
			<div class="modal__header">
				<div id="modal__title">
					<slot name="header" />
				</div>
				<button v-if="!unclosable && !hideCloseButton" class="modal__close" aria-label="sluiten" @click="close" />
			</div>
			<div class="modal__content">
				<slot name="content" />
			</div>
		</div>
		<div class="modal__overlay" @click="close" />
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop, Watch} from "vue-property-decorator"

	import eventBus from "lib/vue/eventBus"

	@Component
	export default class Modal extends Vue {
		@Prop({required: false, type: String}) event?: string
		@Prop({required: false, type: String}) closeEvent?: string
		@Prop({required: false, type: String}) initiator?: string
		@Prop({required: false, type: Boolean}) unclosable?: boolean
		@Prop({required: false, type: Boolean}) hideCloseButton?: boolean

		visible = false
		loaded = false

		@Watch("event")
		eventChanged(event: string | undefined, oldEvent: string | undefined) {
			if (oldEvent) {
				eventBus.off(this, oldEvent, this.display)
			}
			if (event) {
				eventBus.on(event, this.display, this)
			}
		}

		beforeMount() {
			if (this.event) {
				eventBus.on(this.event, this.display, this)
			}
			if (this.closeEvent) {
				eventBus.on(this.closeEvent, this.close, this)
			}
		}

		mounted() {
			// FIXME: timeout necessary to prevent the modal to appear briefly before transitioning.
			setTimeout(() => this.loaded = true, 200)
			
			document.addEventListener("keydown", this.handleTabKey)
		}

		beforeDestroy() {
			if (this.event) {
				eventBus.off(this, this.event, this.display)
			}
			if (this.closeEvent) {
				eventBus.off(this, this.closeEvent, this.close)
			}

			document.removeEventListener("keydown", this.handleTabKey)
		}

		display() {
			this.visible = true
			this.$emit("opened")
			this.focusModalBox()
		}

		close() {
			if (!this.unclosable) {
				this.visible = false
				this.$emit("closed")
				if (this.initiator) {
					document.getElementById(this.initiator)?.focus()
				}
			}
		}

		getModalBoxRef() {
			return this.$refs.modal__box ? (this.$refs.modal__box as HTMLDivElement) : null
		}

		focusModalBox() {
			if (this.visible) {
				setTimeout(() => {
					this.getModalBoxRef()?.focus()
				}, this.loaded ? 1 : 200)
			}
		}

		getFocusableElements() {
			const dialogElement = this.getModalBoxRef()
			if (dialogElement) {
				const focusableChildren = dialogElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
				return [dialogElement, ...focusableChildren]
			}
			return []
		}

		handleTabKey(event: KeyboardEvent) {
			if (this.visible && event.key === "Tab") {
				const focusableElements = this.getFocusableElements()

				if (!focusableElements.length) return

				const lastElement = focusableElements[focusableElements.length - 1]

				if (document.activeElement === lastElement || focusableElements.every(element => element !== document.activeElement)) {
					event.preventDefault()
					this.focusModalBox()
				}
			}
		}
	}
</script>

<style>
	@import "modal/modal.css";
</style>