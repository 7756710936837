<template>
	<div>
		{{ this.text }}
		<div class="beneficiaries" v-for="([sequence, beneficiaries], i) in Array.from(beneficiaries)" :key="i">
			<div>{{ sequence }}.</div>
			<div>
				<div v-for="(beneficiary, index) in beneficiaries" :key="index">
					{{ beneficiary.name || beneficiary.type }}
					<span v-if="beneficiary.percentage !== 100">
						voor {{ beneficiary.percentage }}%
						<span v-if="index+1 < beneficiaries.length">en</span>
					</span>
				</div>
			</div>
		</div>
		<br>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop} from "vue-property-decorator"
	import {Beneficiaries} from "store/policies/types"

	@Component
	export default class PolicyPersons extends Vue {
		@Prop({type: String, required: true}) text!: string
		@Prop({type: Map, required: true}) beneficiaries!: Beneficiaries
	}
</script>

<style>
.beneficiaries {
	display: flex;
}

.beneficiaries > div:first-of-type {
	padding-right: 8px;
}
</style>
