<template>
	<span>
		<div class="input-box reset-margin">
			<div v-if="currentPremium" class="sub-box reset-padding">
				<label>Huidige premie <sup v-if="policyData.multiplePremiums">1</sup></label>
				<div class="current">{{ currentPremium }}</div>
			</div>
			<div class="sub-box reset-padding">
				<label class="input-label">{{ FieldLabels.NEW_PREMIUM }} *</label>
				<decimal-input 
					v-model="premiumAmountData.newPremiumAmount"
					@defineValid="valid => validNumber = valid"
					:beforeComma="4"
					:afterComma="2"
					:minimumNumber="minimumAmount"
					:numberType="NumberType.MONEY"
					:doValidate="true"
					:submitted="submitted"
					placeholder="100,00" 
					@focus="$emit('focusDecimalInput', { ...$event, label: FieldLabels.NEW_PREMIUM })"
					@error="$emit('error', { ...$event, componentUid: 'nieuwe premie' })"
				/>
			</div>
			<accessible-error :show-error="submitted && showValidationEqual">
				<div class="content-box">
					<p>U moet een afwijkende nieuwe premie ten opzichte van de huidige premie invoeren.</p>
				</div>
			</accessible-error>
			<accessible-error :show-error="submitted && showValidationPremiumIncrease">
				<div class="content-box">
					<p>Op basis van (fiscale) voorwaarden is voor deze polis helaas geen hogere premie mogelijk.</p>
				</div>
			</accessible-error>
		</div>
		<div class="input-box reset-border-top">
			<div v-if="policyData.currentPremiumFrequency" class="sub-box">
				<label>Huidige betaaltermijn</label>
				<div class="current">{{ capitalize(policyData.currentPremiumFrequency) }}</div>
			</div>
			<div class="sub-box">
				<select-input 
					v-model="premiumAmountData.selectedPaymentTerm"
					:value="premiumAmountData.selectedPaymentTerm"
					:options="paymentTerms"
					:submitted="submitted"
					@open="$emit('openSelectInput', { ...$event, label: FieldLabels.NEW_PAYMENTTERM })"
					@click="$emit('clickSelectInput', { ...$event, label: FieldLabels.NEW_PAYMENTTERM })"
					@error="$emit('error', { ...$event, componentUid: 'nieuwe premie'})"
				>
					<template #label>{{ FieldLabels.NEW_PAYMENTTERM }} *</template>
				</select-input>
			</div>
			<div class="content-box" v-if="policyData.currentPremiumFrequency && policyData.multiplePremiums">
				<small><strong>1)</strong> Op het polisinformatieblad staat het totale premie overzicht. Bij deze aanpassing geldt 1 nieuwe premie.</small>
			</div>
		</div>
	</span>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { isNull } from "lodash-es"
	import { paymentTerms } from "leven-shared-components/data/defaults"
	import { Term, NumberType } from "leven-shared-components/typings/enums"
	import { PolicyData } from "leven-shared-components/typings/interfaces"
	import DecimalInput from "leven-shared-components/DecimalInput.vue"
	import SelectInput from "leven-shared-components/SelectInput.vue"
	import capitalize from "lib/string/capitalize"
	import { convertMoney } from "leven-shared-components/typings/convert"
	import { addZeros } from "leven-shared-components/utils/validations"
	import AccessibleError from "leven-shared-components/AccessibleError.vue"

	enum FieldLabels {
		NEW_PREMIUM = "Nieuwe premie",
		NEW_PAYMENTTERM = "Nieuwe betaaltermijn"
	}

	interface PremiumAmountData {
		newPremiumAmount: string | null
		selectedPaymentTerm: Term | null
	}

	@Component({
		components: {
			DecimalInput,
			SelectInput,
			AccessibleError
		}
	})
	export default class PremiumAmount extends Vue {
		@Prop({type: Boolean, required: true}) submitted!: boolean
		@Prop({type: Object, required: true}) policyData!: PolicyData

		capitalize = capitalize

		NumberType = NumberType
		FieldLabels = FieldLabels
		paymentTerms = paymentTerms

		minimumAmount = 1
		validNumber = false
		showValidationEqual = false
		showValidationPremiumIncrease = false

		premiumAmountData: PremiumAmountData = {
			newPremiumAmount: null,
			selectedPaymentTerm: null
		}

		async mounted() {
			await Vue.nextTick()
			this.premiumAmountData.selectedPaymentTerm = this.policyData.currentPremiumFrequency || null
			this.changeFields("", false)
		}

		@Watch("premiumAmountData", {deep: true})
		changeSelectedPremiumAmount() {
			let mutation = ""
			let mutationValid = false

			if (this.validSelectedPaymentTerm && this.validNewPremiumAmount) {
				if (this.policyData.currentPremiumTotal) {
					if (this.premiumAmountData.newPremiumAmount === addZeros(this.policyData.currentPremiumTotal.toString().replace(".", ","), 2)) {
							mutationValid = false
							this.showValidationEqual = true
					} else {
							mutationValid = true
							this.showValidationEqual = false
					}
					if (this.policyData.premiumIncreaseNotAllowed &&
						this.premiumAmountData.newPremiumAmount &&
						parseFloat(this.premiumAmountData.newPremiumAmount.replace(",", ".")) > this.policyData.currentPremiumTotal) {
							mutationValid = false
							this.showValidationPremiumIncrease = true
					} else {
							mutationValid = true
							this.showValidationPremiumIncrease = false
					}
				} else {
					mutationValid = true
					this.showValidationEqual = false
					this.showValidationPremiumIncrease = false
				}
			}

			if (mutationValid) {
				mutation = `${FieldLabels.NEW_PREMIUM}: € ${this.premiumAmountData.newPremiumAmount}\n${FieldLabels.NEW_PAYMENTTERM}: ${this.premiumAmountData.selectedPaymentTerm}`
			}
			this.changeFields(mutation, mutationValid)
		}

		changeFields(mutation: string, mutationValid: boolean) {
			this.$emit("changedMutationFields", { mutation, mutationValid })
		}

		get validSelectedPaymentTerm() {
			return !isNull(this.premiumAmountData.selectedPaymentTerm)
		}

		get validNewPremiumAmount() {
			return this.validNumber
		}

		get currentPremium() {
			return this.policyData.currentPremiumTotal ? convertMoney(this.policyData.currentPremiumTotal) : undefined
		}

	}
</script>
