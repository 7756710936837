<template>
	<div class="accordion-list">
		<slot></slot>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class AccordionList extends Vue {}
</script>

<style>
	@import "container/accordion.css";
</style>
