<template functional>
	<label class="input-label input-label--primary">
		<slot name="input"></slot>
		<span class="input-label__label"><slot name="label"></slot></span>
	</label>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class LabeledInput extends Vue {}
</script>

<style>
	@import "form/labeled-input.css";
</style>