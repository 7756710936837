<template>
	<div class="input-box" v-if="policyData && policyData.coInsured.personID">
		<div class="sub-box wide">
			<label class="input-label">{{ fieldLabel }} *</label>
			<select v-model="insuredToDelete" @focus="update('open', defaultOption)">
				<option :value="null">{{ defaultOption }}</option>
				<option :key="policyData.insured.personID" :value="policyData.insured">{{ policyData.insured.insuredType }} - {{ policyData.insured.fullName }}</option>
				<option :key="policyData.coInsured.personID" :value="policyData.coInsured">{{ policyData.coInsured.insuredType }} - {{ policyData.coInsured.fullName }}</option>
			</select>
			<accessible-error
				:show-error="submitted && !validInsuredToDelete"
				:error-message="errorMessage"
			/>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { isNull } from "lodash-es"
	import { InsuredPerson, PolicyData } from "leven-shared-components/typings/interfaces"
	import AccessibleError from "leven-shared-components/AccessibleError.vue"

	@Component({
		components: {
			AccessibleError
		}
	})
	export default class DeleteInsured extends Vue {
		@Prop({type: Boolean, required: true}) submitted!: boolean
		@Prop({type: Object, required: true}) policyData!: PolicyData

		defaultOption = "Maak een keuze"
		fieldLabel = "Te verwijderen verzekerde"
		errorMessage = "Maak a.u.b. een keuze."
		insuredToDelete: InsuredPerson | null = null

		mounted() {
			this.changeFields("", false)
		}

		update(type: 'open' | 'click', textContent: string) {
			this.$emit(type, { textContent })
		}

		@Watch("insuredToDelete")
		changeInsuredToDelete() {

			const mutationValid = this.validInsuredToDelete
			let mutation = ""

			if (mutationValid) {
				mutation = `${this.fieldLabel}:\n${this.insuredToDelete!.insuredType}: ${this.insuredToDelete!.fullName}`
			}

			this.changeFields(mutation, mutationValid)
			this.update("click", "verzekerde")
		}

		changeFields(mutation: string, mutationValid: boolean) {
			this.$emit("changedMutationFields", { mutation, mutationValid })
		}

		get validInsuredToDelete() {
			return !isNull(this.insuredToDelete)
		}

		@Watch("submitted")
		@Watch("validInsuredToDelete")
		checkErrors() {
			if (this.submitted && !this.validInsuredToDelete) {
				this.$emit('error', { errorMessage: this.errorMessage, componentUid: 'te verwijderen verzekerde' })
			}
		}
	}
</script>
