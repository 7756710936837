import Vue from "vue"
import {Module} from "vuex"
import {isUndefined} from "lodash-es"
import {RootState} from "../types"
import {CommunicationState, CommunicationDocument, PolicyDocument, ClaimDocument, Attachment, PollClaim, FaqsPerPolicy, PolicyDocumentAegon} from "store/communication/types"
import {communication} from "api/gateway"
import {convertAttachments, convertFAQs} from "./conversion"
import {Message, FAQ, FAQPolicyData} from "api/models/communications"
import {Policy} from "store/policies/types"

const communicationState: CommunicationState = {
	customerLoaded: false,
	policyLoaded: false,
	policyAegonLoaded: false,
	claimLoaded: false,
	customer: [],
	policy: [],
	policyAegon: [],
	claim: [],
	pollClaim: {
		poll: false,
		numberOfDocuments: undefined
	},
	messagesLoaded: false,
	messages: [],
	generalFaqsLoaded: false,
	generalFaqs: [],
	policyFaqsLoaded: false,
	policyFaqs: []
}

const communicationModule: Module<CommunicationState, RootState> = {
	mutations: {
		customer(state, documents?: Array<CommunicationDocument>) {
			if (documents) {
				state.customer = documents
				state.customerLoaded = true
			}
		},
		policy(state, documents?: Array<PolicyDocument>) {
			if (documents) {
				state.policy = documents
				state.policyLoaded = true
			}
		},
		policyAegon(state, documents?: Array<PolicyDocumentAegon>) {
			if (documents) {
				state.policyAegon = documents
				state.policyAegonLoaded = true
			}
		},
		claim(state, documents?: Array<ClaimDocument>) {
			if (documents) {
				state.claim = documents
				state.claimLoaded = true
			}
		},
		documentSubject(_, payload: {document: CommunicationDocument, newSubject: string}) {
			Vue.set(payload.document, "subject", payload.newSubject)
		},
		content(_, payload: {document: CommunicationDocument, content: string, attachments: Array<Attachment>}) {
			// The document is already contained in the store, so we might just as well update it right away.
			Vue.set(payload.document, "content", payload.content)
			Vue.set(payload.document, "attachments", payload.attachments)
		},
		pollclaim(state, payload: PollClaim) {
			state.pollClaim = payload
		},
		messages(state, payload: Array<Message>) {
			state.messages = payload
			state.messagesLoaded = true
		},
		generalfaqs(state, payload: Array<FAQ>) {
			state.generalFaqs = payload
			state.generalFaqsLoaded = true
		},
		policyfaqs(state, payload: Array<FaqsPerPolicy>) {
			state.policyFaqs = payload
			state.policyFaqsLoaded = true
		}
	},
	getters: {
		documents(state): Array<CommunicationDocument> {
			return [...state.customer, ...state.policy, ...state.claim].sort((a, b) => a.communicationId > b.communicationId ? -1 : 1)
		},
		documentsAegon(state): Array<PolicyDocumentAegon> {
			return [...state.policyAegon].sort((a, b) => b.date.getTime() - a.date.getTime())
		},
		hasAegonPolicyDocuments(state): boolean {
			return state.policyAegon.length > 0
		}
	},
	actions: {
		async fetchContent(context, document: CommunicationDocument): Promise<void> {
			if (isUndefined(document.content)) {
				const data = await communication.getDocumentContent(document.externalIdentifier)

				const attachments = convertAttachments(data)

				if (document.subject === "Bericht met bijlage") {
					let newSubject = `${document.subject}<br>`
					const externalDocumentAttachments = attachments?.filter(attachment => attachment.type === "Extern document")
					if (externalDocumentAttachments) {
						for (const attachment of externalDocumentAttachments) {
							newSubject = `${newSubject} - ${attachment.fileName}<br>`
						}
						context.commit("documentSubject", {document, newSubject})
					}
				}

				const messageBody = data.formattedEmailMessageBody || ""
				if (messageBody) {
					// The content contains a complete HTML document. We only want the body.
					const parser = new DOMParser()
					const content = parser.parseFromString(messageBody, "text/html")
					// Remove all style elements inside the body and extract the HTML that is left.
					for (const style of content.body.getElementsByTagName("style")) {
						style.parentElement!.removeChild(style)
					}
					context.commit("content", {document, content: content.body.innerHTML, attachments})
				} else {
					context.commit("content", {document, content: "", attachments})
				}
			}
		},
		async getPolicyFaqs(context): Promise<void> {

			const policies: Array<Policy> = [...context.rootState.policies!!.policies]
			const faqsArray: Array<FaqsPerPolicy> = []

			for (const policy of policies) {
				const index = faqsArray.findIndex(policyFAQ => policyFAQ.policyID === policy.policyIdentifier)

				if (index === -1) {

					const faqPolicyData: FAQPolicyData = {
						productCode: policy.productId,
						clauseCode: policy.clause ? policy.clause.code : null,
						packagePolicy: policy.packagePolicy,
						pledgedPolicy: policy.pledgeHolders && policy.pledgeHolders.length > 0 || false
					}

					faqsArray.push({
						policyID: policy.policyIdentifier,
						productName: policy.product,
						faqs: await convertFAQs(await communication.getPolicyFAQs(faqPolicyData)) || []
					})
				}
			}

			context.commit("policyfaqs", faqsArray)
		}
	},
	namespaced: true,
	state: communicationState
}

export default communicationModule
