<template>
	<div class="input-box">
		<div class="amount-box">
			<label class="input-label">{{ FieldLabels.AMOUNT_EXTRADEPOSIT }} *</label>
			<decimal-input 
				v-model="depositData.amount"
				@defineValid="valid => validAmount = valid"
				@focus="$emit('focusDecimalInput', { ...$event, label: FieldLabels.AMOUNT_EXTRADEPOSIT })"
				@error="$emit('error', { ...$event, componentUid: 'bedrag extra storting' })"
				:beforeComma="5"
				:afterComma="2"
				:minimumNumber="minimumAmount"
				:numberType="NumberType.MONEY"
				:doValidate="true"
				:submitted="submitted"
				:placeholder="`${minimumAmount},00`" 
			/>
		</div>

		<div v-if="showPremiumReductionOptions">
			<p class="leftPadding"><i>Vul hier het maximaal gewenste bedrag in. Bij het maken van de offerte controleren wij het fiscaal toegestane maximum bedrag.</i></p>
			<radio-input 
				v-model="depositData.premiumReduction"
				:options="premiumReductionOptions" 
				:block="true" 
				class="normal"
				@change="$emit('updatePremiumReduction', { event: $event, label: FieldLabels.PREMIUM_REDUCTION })"
			>
				<template #label>{{ FieldLabels.PREMIUM_REDUCTION }} *</template>
			</radio-input>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { NumberType, YesNo } from "leven-shared-components/typings/enums"
	import DecimalInput from "leven-shared-components/DecimalInput.vue"
	import RadioInput from "leven-shared-components/RadioInput.vue"
	import { Clause, ClauseCode, OptionModel, toOptionModel } from "leven-shared-components/typings/interfaces"

	enum FieldLabels {
		AMOUNT_EXTRADEPOSIT = "Bedrag extra storting",
		PREMIUM_REDUCTION = "Uitgangspunt"
	}

	interface DepositData {
		amount: string
		premiumReduction: YesNo
	}

	@Component({
		components: {
			DecimalInput,
			RadioInput
		}
	})
	export default class ExtraDeposit extends Vue {
		@Prop({type: Boolean, required: true}) submitted!: boolean
		@Prop({type: Object, required: true}) clause!: Clause

		NumberType = NumberType
		YesNo = YesNo
		FieldLabels = FieldLabels

		premiumReductionOptions: Array<OptionModel> = [
			toOptionModel(YesNo.YES, "Verlaag de premie zodat het (verwachte) eindkapitaal gelijk blijft."),
			toOptionModel(YesNo.NO, "Houd de premie en het (verwachte) eindkapitaal gelijk, maar kort de looptijd in.")
		]

		minimumAmount = 500
		validAmount = false

		depositData: DepositData = {
			amount: "",
			premiumReduction: YesNo.YES
		}

		mounted() {
			this.changeFields("", false)
		}

		@Watch("depositData", { deep: true })
		changeDepositData() {
			const mutationValid = this.validAmount

			const premiumReduction = this.showPremiumReductionOptions ?
					`\n${FieldLabels.PREMIUM_REDUCTION}: ${this.premiumReductionOptions.find(option => option.value === this.depositData.premiumReduction)?.label}` : ""

			const mutation = `${FieldLabels.AMOUNT_EXTRADEPOSIT}: € ${this.depositData.amount}${premiumReduction}`

			this.changeFields(mutation, mutationValid)
		}

		changeFields(mutation: string, mutationValid: boolean) {
			this.$emit("changedMutationFields", { mutation, mutationValid })
		}

		get showPremiumReductionOptions() {
			const clauses: Array<ClauseCode> = [ClauseCode.KAP_BOX1,
												ClauseCode.KAP_Box3,
												ClauseCode.KAP_BHW_BV,
												ClauseCode.KEW,
												ClauseCode.KAP_pBHW,
												ClauseCode.KAP_BHW]

			return clauses.find(clauseCode => clauseCode === this.clause.code)
		}
	}
</script>
