<template>
	<modal class="modal--padded" :event="event" :initiator="initiator">
		<template v-if="modalHeader" slot="header">{{ modalHeader }}</template>
		<template slot="content">
			<template v-if="modalComponent">
				<component :is="modalComponent"></component>
			</template>
			<template v-else>
				<h3 v-if="modalTitle">{{ modalTitle }}</h3>
				<p v-if="modalText" v-html="modalText"></p>
			</template>
		</template>
	</modal>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import eventBus from "lib/vue/eventBus"
	import Modal from "components/modal/Modal.vue"
	import InformationModalBankStatement from "leven-shared-components/InformationModalBankStatement.vue"

	@Component({
		components: {
			InformationModalBankStatement,
			Modal
		}
	})
	export default class InformationModal extends Vue {
		event = "informationModal"
		initiator: string | null = null
		modalHeader: any | null = null
		modalTitle: any | null = null
		modalText: any | null = null
		modalComponent: any | null = null

		InformationModalBankStatement = InformationModalBankStatement

		beforeMount() {
			eventBus.on(this.event, (header?: any, title?: any, text?: any, component?: any, initiator?: any) => {
				this.initiator = initiator
				this.modalHeader = header
				this.modalTitle = title
				this.modalText = text
				this.modalComponent = component
			})
		}
	}
</script>
