<template>
    <link-button 
        class="button--primary button--back"
        :class="{ 'button--margin-bottom': withMarginBottom === true }"
        :href="href || '#'"
        @click="$emit('click', $event)"
    >
        <icon-arrow class="icon-arrow" /> {{ text || 'Terug' }}
    </link-button>
</template>

<script lang="ts">
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import { Prop } from 'vue-property-decorator'
    import LinkButton from 'components/button/LinkButton.vue'
    import { IconArrow } from "custom/icons"

    @Component({
        components: {
            LinkButton,
            IconArrow
        }
    })
    export default class BackButton extends Vue {
        @Prop({ type: String, required: false }) href?: string
        @Prop({ type: String, required: false }) text?: string
        @Prop({ type: Boolean, required: false }) withMarginBottom?: boolean
    }
</script>

<style>
    .button--primary {
        margin-top: 3rem;
        min-height: 40px;
    }

    .button--margin-bottom {
        margin-bottom: 3rem;
    }

    .icon-arrow {
        vertical-align: middle;
    }
</style>
