<template>
	<div class="box-content">
		<div class="request-loader">
			<div><img src="./assets/ajax-loader.gif" /></div>
			<div>{{ loadingMessage }}</div>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	@Component
	export default class RequestLoader extends Vue {
		@Prop({type: String, required: false}) loadingMessage!: string
	}
</script>

<style>
	@import "./assets/request-holder.css";
</style>
