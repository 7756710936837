<template>
	<section class="card" :class="{'has-icon': hasIcon}">
		<header class="card__header">
			<div class="card__icon" v-if="hasIcon">
				<slot name="icon"></slot>
			</div>
			<h3 class="card__title" v-if="hasTitle">
				<slot name="title"></slot>
			</h3>
		</header>
		<div class="card__content">
			<slot></slot>
		</div>
	</section>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class Card extends Vue {
		hasTitle = false
		hasIcon = false

		mounted() {
			this.hasTitle = !!this.$slots.title
			this.hasIcon = !!this.$slots.icon
		}
	}
</script>

<style>
	@import "panel/card.css";

	.card__title {
		margin-block-end: 0;
	}
</style>