import {Module} from "vuex"
import {RootState} from "../types"
import {AnnuityExpirationDestination, Routing} from "./types"
import {Policy, ClaimDocument, Claim, Premiums} from "store/policies/types"
import * as rules from "../rules"
import {recentlyExpiredWithAllowedProduct} from "leven-shared-components/typings/sharedrules"
import diff, {Diff} from "lib/date/diff"
import {sumBy} from "lodash-es"

const routingState: Routing = {
	selectedDocumentId: undefined,
	selectedProfileItem: undefined,
	selectedWidget: undefined,
	selectedPolicy: undefined,
	selectedClaim: undefined,
	changeCommunicationPreferenceClicked: false,
	communicationPreferenceAcquired: false,
	payoutFormBeneficiaryConsentSubmitted: false,
	payoutToPledgeHolderRequested: false,
	annuityExpirationDestination: AnnuityExpirationDestination.UNDEFINED
}

const routing: Module<Routing, RootState> = {
	getters: {
		selectedPolicy: (state): Policy => state.selectedPolicy!!,
		statusExpired: (_, getters): boolean => rules.statusExpired(getters.selectedPolicy),
		hasOpenSystemExpirationClaim: (_, getters): boolean => rules.hasOpenSystemExpirationClaim(getters.selectedPolicy),
		hasOpenAdvisorClaim: (_, getters): boolean => rules.hasOpenAdvisorClaim(getters.selectedPolicy),
		isExcludedFromPayoutProcess: (_, getters): boolean => rules.isExcludedFromPayoutProcess(getters.selectedPolicy),
		expirationProduct: (_, getters): boolean => rules.expirationProduct(getters.selectedPolicy),
		pendingDone: (_, getters): boolean => rules.pendingDone(getters.selectedPolicy),
		pendingClaim: (_, getters): boolean => rules.pendingClaim(getters.selectedPolicy),
		documentsToUpload: (_, getters): Array<ClaimDocument> => rules.documentsToUpload(getters.selectedPolicy),
		documentsUploaded: (_, getters): boolean => rules.documentsUploaded(getters.selectedPolicy),
		approved: (_, getters): boolean => rules.approved(getters.selectedPolicy),
		redeemable: (_, getters): boolean => rules.redeemable(getters.selectedPolicy),
		isRecentlyExpiredWithAllowedProduct: (_, getters): boolean => recentlyExpiredWithAllowedProduct(getters.selectedPolicy.status,
			getters.selectedPolicy.productId, getters.selectedPolicy.endDate),
		selectedClaim: (state): Claim => state.selectedClaim!!,
		premiums(state): Premiums {

			const premiumData: Premiums = {
				current: [],
				future: [],
				multiplePremiums: false,
				currentTotal: undefined,
				currentFrequency: undefined
			}

			const validPremiums = state.selectedPolicy?.premiums?.filter(premium => premium.paymentFrequency && premium.dateStart && premium.dateEnd)

			if (validPremiums && validPremiums.length) {
				for (const premium of validPremiums) {

					const passedStartDate = diff(Diff.DAYS, new Date(), premium.dateStart!) < 0
					const beforeEndDate = diff(Diff.DAYS, new Date(), premium.dateEnd!) > 0

					const futureStartDate = diff(Diff.DAYS, new Date(), premium.dateStart!) >= 0

					if (passedStartDate && beforeEndDate) {
						premiumData.current.push(premium)
					}

					if (futureStartDate) {
						premiumData.future.push(premium)
					}
				}

				if (premiumData.current.length) {
					premiumData.multiplePremiums = premiumData.current.length + premiumData.future.length > 1
					premiumData.currentTotal = sumBy(premiumData.current, "amount")
					premiumData.currentFrequency = premiumData.current[0].paymentFrequency!
				}
			}

			return premiumData
		}
	},
	mutations: {
		selectDocumentId: (state, payload: string | number | undefined) => state.selectedDocumentId = payload,
		selectProfileItem: (state, payload: string | undefined) => state.selectedProfileItem = payload,
		selectWidget: (state, payload: "addAfkoop" | "addExpiratie" | "complete" | undefined) => state.selectedWidget = payload,
		selectPolicy: (state, payload: Policy | undefined) => state.selectedPolicy = payload,
		selectClaim: (state, payload: Claim | undefined) => state.selectedClaim = payload,
		initChangeCommunicationPreference: (state, payload: boolean) => state.changeCommunicationPreferenceClicked = payload,
		setCommunicationPreferenceAcquired: (state, payload: boolean) => state.communicationPreferenceAcquired = payload,
		setPayoutFormBeneficiaryConsentSubmitted: (state, payload: boolean) => state.payoutFormBeneficiaryConsentSubmitted = payload,
		setPayoutToPledgeHolderRequested: (state, payload: boolean) => state.payoutToPledgeHolderRequested = payload,
		setAnnuityExpirationDestination: (state, payload: AnnuityExpirationDestination) => state.annuityExpirationDestination = payload
	},
	namespaced: true,
	state: routingState
}

export default routing
