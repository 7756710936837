<template>
	<div>
		<div 
			:id="`accordionitem.${id}`"
			class="accordion-item"
			tabindex="0"
			:aria-labelledby="`accordionlabel1.${id} accordionlabel2.${id}`"
			@click.self="toggle"
			@keydown.enter.self.prevent="toggle"
		>
			<label :id="`accordionlabel1.${id}`" class="displayhidden-ariavisible" >{{ open ? 'geopend' : 'gesloten' }}</label>
			<label v-if="ariaLabel" :id="`accordionlabel2.${id}`" class="displayhidden-ariavisible" >{{ ariaLabel }}</label>
			
			<div class="accordion__holder" :class="{ 'selected' : open }">
				<input v-if="single" class="accordion__input" type="radio" :id="id" :checked="open" @click="toggle">
				<input v-else class="accordion__input" type="checkbox" :id="id" :checked="open" @click="toggle">
				<label :for="id" class="accordion__anchor">
					<div class="accordion__header">
						<div class="accordion__icon" />
						<div class="accordion__title" :aria-hidden="ariaLabel ? 'true' : 'false'">
							<slot name="title" />
						</div>
					</div>
				</label>
				<div v-show="open" class="accordion__container">
					<div class="accordion__content">
						<slot />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import {Prop, Watch} from "vue-property-decorator"

	@Component
	export default class AccordionItem extends Vue {
		@Prop({type: String, required: true}) id!: string
		@Prop({type: String, required: true}) clicked!: string
		@Prop({type: Boolean, default: false, required: false}) opened!: boolean
		@Prop({type: Boolean, default: false, required: false}) single!: boolean
		@Prop({type: String, required: false}) tealiumTitle!: string
		@Prop({type: String, required: false}) ariaLabel!: string

		open = false

		async mounted() {
			await Vue.nextTick()
			this.open = this.opened
		}

		@Watch("clicked")
		changedSingle() {
			this.open = this.id !== this.clicked ? false : true
		}

		toggle(event: MouseEvent | KeyboardEvent) {
			this.open = !this.open
			this.$emit("toggle", this.open, event)
		}
	}
</script>

<style>
	input.accordion__input {
		position: absolute;
		display: none;
	}

	.accordion__container {
		max-height: 0;
		transition: all 0.55s;
	}

	input.accordion__input:checked ~ .accordion__container {
		max-height: 100vh;
		overflow-y: auto;
	}
</style>