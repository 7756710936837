<template>
	<span>
		<label class="input-label">Uw gegevens</label>
		<label class="input-label">Naam contactpersoon *</label>
		<input type="text" v-model="intermediaryContactDetails.name" class="contact" :placeholder="placeHolderText" />
		<accessible-error
			:show-error="submitted && !validName"
			error-message="Vul a.u.b een contactpersoon in."
		/>

		<label class="input-label">E-mailadres *</label>
		<input type="text" v-model="intermediaryContactDetails.emailAddress" class="contact" :placeholder="placeHolderText" />
		<accessible-error
			:show-error="submitted && !validEmailAddress"
			error-message="Vul a.u.b een geldig e-mailadres in."
		/>

		<label class="input-label">Telefoonnummer *</label>
		<input type="text" v-model="intermediaryContactDetails.phoneNumber" class="contact" :placeholder="placeHolderText" />
		<accessible-error
			:show-error="submitted && !validPhoneNumber"
			error-message="Vul a.u.b een geldig telefoonnummer in."
		/>
	</span>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { email } from "lib/validation/formats"
	import { IntermediaryContactDetails } from "leven-shared-components/typings/interfaces"
	import { isUndefined } from "lodash-es"
	import AccessibleError from "./AccessibleError.vue"

	@Component({
		components: {
			AccessibleError
		}
	})
	export default class IntermediaryContact extends Vue {
		@Prop({type: Boolean, required: true}) submitted!: boolean
		@Prop({type: Object, required: true}) intermediaryContactDetails!: IntermediaryContactDetails

		placeHolderText = "Voor als wij nog vragen hebben"

		mounted() {
			this.changeContactDetails()
		}

		@Watch("intermediaryContactDetails", { deep: true })
		changeContactDetails() {
			const contactDetailsValid = this.validName && this.validEmailAddress && this.validPhoneNumber

			const contactDetailsString = `Uw contactgegevens:\nContactpersoon: ${this.intermediaryContactDetails.name}\nE-mailadres: ${this.intermediaryContactDetails.emailAddress}\nTelefoonnummer: ${this.intermediaryContactDetails.phoneNumber}`

			this.$emit("changedContactDetails", { contactDetailsString, contactDetailsValid })
		}

		get validName() {
			return !isUndefined(this.intermediaryContactDetails.name) && this.intermediaryContactDetails.name !== ""
		}

		get validEmailAddress() {
			if (this.intermediaryContactDetails.emailAddress) {
				this.intermediaryContactDetails.emailAddress = this.intermediaryContactDetails.emailAddress.replace(/\s/g, "")
				return email(this.intermediaryContactDetails.emailAddress)
			}
			return false
		}

		get validPhoneNumber() {
			if (this.intermediaryContactDetails.phoneNumber) {
				return this.intermediaryContactDetails.phoneNumber !== "" && /^[+]?[0-9]{1,4}[\s\0-9]*$/.test(this.intermediaryContactDetails.phoneNumber)
			}
			return false
		}

	}
</script>
